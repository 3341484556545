import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import style from "./BookCard.module.css";
import read_n from "../../assets/primary/read_n@1x.svg";
import readbtn from "../../assets/primary/read_h.svg";
import read_n1 from "../../assets/primary/read_n@1x copy.svg";
import readbtn1 from "../../assets/primary/read_h copy.svg";
import icon from "../../assets/secondary/icon@1x.svg";
import icon2 from "../../assets/primary/fav_h.svg";
import icon3 from "../../assets/icons/icon copy@1x.svg";
import cart_h from "../../assets/primary/cart_h@1x.svg";
import cart from "../../assets/primary/cart.svg";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { addToShoppingCart } from "../../redux/services/ShoppingCartService";
import { connect } from "react-redux";
import {
  addToFavorite,
  deleteFavorite,
} from "../../redux/services/FavoriteService";
import { useSelector } from "react-redux";
import { handleReaderMode } from "../../redux/actions/reader/reader";
import { openBook } from "../../utils/openBook";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import Loader from "../Loader/Loader";

export interface ICategoriesData {
  bannerImage: string | null;
  categoryId: number;
  categoryName: string;
  categoryUrlNaming: string;
  isHeader: null;
}

export interface IBookData {
  categories: any;
  displayProductAuthor: string;
  displayProductDescriptionDetail: string;
  displayProductDescriptionFeature: string;
  displayProductDescriptionIngredient: string;
  displayProductDescriptionTarget: string;
  displayProductDescriptionUsage: string;
  displayProductDescriptionUseCountstring: string;
  displayProductDiscountPrice: number;
  displayProductId: number;
  displayProductImage: string;
  displayProductNameChi: string;
  displayProductNameEng: string;
  displayProductNameHtmlChi: string | null;
  displayProductNameHtmlEng: string | null;
  displayProductOriginalPrice: number;
  displayProductPrice: number;
  displayProductShortDescription: string;
  displayProductUrlNaming: string;
  ePubProdFileName: string;
  functionalIcon: string;
  hashtags: [];
  marketingEvents: any;
  memberOutput: any;
  metadesc: any;
  metatitle: any;
  offShelfDate: string;
  onShelfDate: any;
  order: any;
  products: any;
  recommended: any;
  recommendeds: any;
  status: number;
  isbn: string;
  publisher: string;
  ctsId: string;
  language: string;
  purchased: boolean;
  displayProductTranslator:string
}

interface IProps {
  props: IBookData;
  handleReaderMode: any;
  reader: any;
  member: any;
}

function BookCard({ props, handleReaderMode, reader, member }: IProps) {
  const [tempProductId, setTempProductId] = useState<number | null>();
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingProd, setLoadingProd] = useState(false);
  const [loadingOnlineRead, setLoadingOnlineRead] = useState(false);
  const [loader, setLoader] = useState(false);
  const [htmlString, setHtmlString] = useState("");

  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    var html = props.displayProductDescriptionFeature;
    var div = document.createElement("div");
    div.innerHTML = html;
    setHtmlString(div.innerText);
  }, []);

  useEffect(() => {
    //import StorageUtil from "../../../utils/reader/serviceUtils/storageUtil";
    if (tempProductId !== undefined && tempProductId !== null) {
      console.log("tempProductId", tempProductId);
      let readerConfig = localStorage.getItem("readerConfig");
      if (readerConfig !== null) {
        let readerConfigObj = JSON.parse(readerConfig);
        if (reader.mode !== "" && reader.mode !== undefined) {
          readerConfigObj["rm"] = reader.mode.substring(0, 1);
          localStorage.setItem("readerConfig", JSON.stringify(readerConfigObj));
          if (reader.mode === "read") {
            setLoadingProd(true);
            //handleReaderMode("");
          }

          if (reader.mode === "preview") {
            setLoadingPreview(true);
            //handleReaderMode("");
          }

          // if (props.reader.mode === "online") {
          //   setLoadingOnlineRead(true);
          //   handleReaderMode("");
          // }
        }
      }
    }

    // if (props.userInfo.mode !== undefined) {
    //   console.log("props10", props.userInfo.mode);
    //   if (props.reader.mode === "read") {
    //     setLoadingProd(true);
    //   }
    //   if (props.reader.mode === "preview") {
    //     setLoadingPreview(true);
    //   }
    // }
  }, [reader]);

  useEffect(() => {
    if (loadingPreview || loadingProd) {
      console.log("props.products[0]", props.products[0]);
      openBook(props.products[0], setLoader);
      setLoadingPreview(false);
      setLoadingProd(false);
      setLoadingOnlineRead(false);
      setTempProductId(null);
    }
  }, [loadingPreview, loadingProd]);

  const addToCart = () => {
    if (member.token !== null) {
      addToShoppingCart(props && props.products[0].productId, 1);
    } else {
      alert("請先登入");
    }
  };

  const addToFavourite = () => {
    console.log("addToFavouriteprops", props);
    alert("成功加入我的最愛");
    addToFavorite(props.displayProductId);
  };

  const favourite = useSelector(
    (state: any) => state.FavoriteReducer.productList
  );
  const bookshelf = useSelector(
    (state: any) => state.MyBookShelfReducer.productList
  );
  const shoppingCart = useSelector(
    (state: any) => state.shoppingCartReducer.productList
  );

  const handleReadBook = (actionType: string, productId: number) => {
    setTempProductId(productId);
    setLoader(true);
    if (
      actionType === "read" ||
      actionType === "preview" ||
      actionType === "online"
    ) {
      handleReaderMode(actionType);
      //setReadAction(actionType);
    } else {
      //   setLoadingOnlineRead(true);
    }
  };
  return (
    <div className={style.bookCardContainer}>
      <div
        className={style.imgContainer}
        onClick={() => navigate(`/product/${props.displayProductUrlNaming}`)}
      >
        <img
          src={
            props &&
            process.env.REACT_APP_BACKEND +
              "file/" +
              props.displayProductImage.split(":::")[0]
          }
          className={style.bookImg}
          alt={"description"}
        ></img>
      </div>
      <div className={style.mainLayout}>
        <div>
          <p className={style.bookTitle} title={props.displayProductNameChi}>
            {props.displayProductNameChi}
          </p>
        </div>
        <div>
          <span className={style.bookTitle} title={props.displayProductAuthor}>
            {props.displayProductAuthor}
          </span>
        </div>

        {props.marketingEvents.length === 0 ? (
          <></>
        ) : (
          <div className={style.discount}>
            <p className={style.discountTag}>
              {
                props.marketingEvents[0].marketingEventName.split(":::")[
                  i18n.language === "en" ? 1 : 0
                ]
              }
            </p>

            <div className={style.triangle}></div>
          </div>
        )}

        <div className={style.priceContainer}>
          <p>{t("bookCard.price")} : HKD&nbsp;</p>
          <p
            className={[
              style.originalPrice
              , props.displayProductOriginalPrice
                ? props.displayProductOriginalPrice == 0
                  ? style.originalPriceHide
                  : props.displayProductDiscountPrice === null
                    ? props.displayProductOriginalPrice == props.displayProductPrice
                      ? style.originalPriceHide
                      : ""
                    : props.displayProductOriginalPrice == props.displayProductDiscountPrice
                      ? style.originalPriceHide
                      : ""
                : style.originalPriceHide
            ].join(" ")}
          >
            {
              props.displayProductOriginalPrice
                ? props.displayProductOriginalPrice.toFixed(2)
                : "0.00"
            }
          </p>
          &nbsp;
          {props.displayProductDiscountPrice === null ? (
            <p style={{ color: "rgb(65,152,122)" }}>
              {
                (Math.floor((props.displayProductPrice + Number.EPSILON) * 10) / 10).toFixed(2)
              }
            </p>
          ) : (
            <>
              <p className={style.originalPrice}>{props.displayProductPrice}</p>
              &nbsp;&nbsp;
              <p style={{ color: "rgb(65,152,122)" }}>
                {
                  (Math.floor((props.displayProductDiscountPrice + Number.EPSILON) * 10) / 10).toFixed(2)
                }
              </p>
            </>
          )}
        </div>
        <div className={style.feature}>
          <p
            title={htmlString}
            dangerouslySetInnerHTML={{
              __html: props.displayProductDescriptionFeature,
            }}
          ></p>
        </div>
      </div>
      <div className={style.buttonContainer}>
        {favourite && favourite.indexOf(props.displayProductId) != -1 ? (
          <>
            <Button
              buttonType={"button"}
              styleType={"cardButton"}
              callBack={() => deleteFavorite(props.displayProductId)}
            >
              <img
                src={icon3}
                onMouseOver={(e) => (e.currentTarget.src = icon2)}
                onMouseOut={(e) => (e.currentTarget.src = icon3)}
                alt={"description"}
              />
            </Button>
          </>
        ) : (
          <Button
            buttonType={"button"}
            styleType={"cardButton"}
            callBack={() => addToFavourite()}
          >
            <img
              src={icon}
              onMouseOver={(e) => (e.currentTarget.src = icon2)}
              onMouseOut={(e) => (e.currentTarget.src = icon)}
              alt={"description"}
            />
          </Button>
        )}

        <Button
          buttonType={"button"}
          styleType={"cardButton"}
          callBack={() => handleReadBook("preview", props.displayProductId)}
        >
          <img
            src={read_n}
            alt={"description" + props.displayProductId}
            onMouseOver={(e) => (e.currentTarget.src = readbtn)}
            onMouseOut={(e) => (e.currentTarget.src = read_n)}
          ></img>
        </Button>

        {props.purchased ? (
          loader ? (
            <Loader />
          ) : (
            <Button
              buttonType={"button"}
              styleType={"cardButton"}
              callBack={() => handleReadBook("read", props.displayProductId)}
            >
              <img
                src={read_n1}
                alt={"description"}
                onMouseOver={(e) => (e.currentTarget.src = readbtn1)}
                onMouseOut={(e) => (e.currentTarget.src = read_n1)}
              ></img>
            </Button>
          )
        ) : shoppingCart.find(
            (item: any) =>
              item.product_display_product === props.displayProductId
          ) != undefined ? (
          <Button
            buttonType={"button"}
            styleType={"cardButton"}
            callBack={() => alert("已加入購物車")}
          >
            <img src={cart_h} alt={"description"} />
          </Button>
        ) : (
          <Button
            buttonType={"button"}
            styleType={"cardButton"}
            callBack={() => addToCart()}
          >
            <img
              src={cart}
              onMouseOver={(e) => (e.currentTarget.src = cart_h)}
              onMouseOut={(e) => (e.currentTarget.src = cart)}
              alt={"description"}
            />
          </Button>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    reader: state.reader,
    member: state.MemberReducer,
  };
};

const actionCreator = {
  handleReaderMode,
};
export default connect(mapStateToProps, actionCreator)(BookCard);
