import React from "react";
import { isElectron } from "react-device-detect";
import { connect } from 'react-redux';
import { readerSettingList } from "../../../../constants/reader/settingList";
import StorageUtil from "../../../../utils/reader/serviceUtils/storageUtil";
import TextToSpeech from "../../textToSpeech/component";
import { Trans } from "react-i18next";
class SettingSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVertical: StorageUtil.getReaderConfig("isVertical") === "yes",
      isBold: StorageUtil.getReaderConfig("isBold") === "yes",
      isIndent: StorageUtil.getReaderConfig("isIndent") === "yes",
      isSliding: StorageUtil.getReaderConfig("isSliding") === "yes",
      isUnderline: StorageUtil.getReaderConfig("isUnderline") === "yes",
      isShadow: StorageUtil.getReaderConfig("isShadow") === "yes",
      isItalic: StorageUtil.getReaderConfig("isItalic") === "yes",
      isInvert: StorageUtil.getReaderConfig("isInvert") === "yes",
      isHideBackground:
        StorageUtil.getReaderConfig("isHideBackground") === "yes",
      isHideFooter: StorageUtil.getReaderConfig("isHideFooter") === "yes",
      isHideHeader: StorageUtil.getReaderConfig("isHideHeader") === "yes",
      isHidePageButton:
        StorageUtil.getReaderConfig("isHidePageButton") === "yes",
      isHideMenuButton:
        StorageUtil.getReaderConfig("isHideMenuButton") === "yes",
      isApplyFont: StorageUtil.getReaderConfig("isApplyFont") === "yes",
      isShowFontSize: StorageUtil.getReaderConfig("isShowFontSize") === "yes",
    };
  }

  _handleRest = () => {
    if (isElectron) {
      alert("Take effect at next startup");
    } else {
      window.location.reload();
    }
  };

  _handleChange = (stateName) => {
    this.setState({ [stateName]: !this.state[stateName] }, () => {
      StorageUtil.setReaderConfig(
        stateName,
        this.state[stateName] ? "yes" : "no"
      );
      alert("Change Successfully");
      setTimeout(() => {
        this.props.renderFunc();
      }, 500);
    });
  };

  handleChange = (stateName) => {
    let _flag1 = !this.state[stateName];
    let _flag2 = this.state[stateName];
    this.setState({ [stateName]: _flag1 });
    StorageUtil.setReaderConfig(
      stateName,
      _flag2 ? "no" : "yes"
    );
	
    if (
      stateName == "isShowFontSize"
      || stateName == "isApplyFont"
    ) {
      if (stateName == "isShowFontSize") {
        this.setState({ ["isApplyFont"]: _flag1 });
        StorageUtil.setReaderConfig(
          "isApplyFont",
          _flag2 ? "no" : "yes"
        );
      }
      if (stateName == "isApplyFont") {
        this.setState({ ["isShowFontSize"]: _flag1 });
        StorageUtil.setReaderConfig(
          "isShowFontSize",
          _flag2 ? "no" : "yes"
        );
      }
    } else {
      alert("Change Successfully");
    }
	
    setTimeout(() => {
      this._handleRest();
    }, 500);
  };
  render() {
    return (
      <>
        {/*
        <TextToSpeech />
        */}
        {readerSettingList.filter((obj) => {
          if (obj.isPubilc === undefined) {
            return true
          } else {
            return obj.isPubilc
          }
        }).map((item) => (
          <div
            className="single-control-switch-container"
            propName={item.propName}
            key={item.propName}
          >
            <span className="single-control-switch-title">
              <Trans>{item.title}</Trans>
            </span>

            <span
              className={["single-control-switch", this.state[item.propName] ? "" : "off"].join(" ")}
              onClick={() => {
                switch (item.propName) {
                  case "isVertical":
                    this.handleChange("isVertical");
                    break;
                  case "isBold":
                    this._handleChange("isBold");
                    break;
                  case "isIndent":
                    this._handleChange("isIndent");
                    break;
                  case "isSliding":
                    this._handleChange("isSliding");
                    break;
                  case "isItalic":
                    this._handleChange("isItalic");
                    break;
                  case "isUnderline":
                    this._handleChange("isUnderline");
                    break;
                  case "isShadow":
                    this._handleChange("isShadow");
                    break;
                  case "isInvert":
                    this._handleChange("isInvert");
                    break;
                  case "isHideFooter":
                    this.handleChange("isHideFooter");
                    break;
                  case "isHideHeader":
                    this.handleChange("isHideHeader");
                    break;
                  case "isHideBackground":
                    this.handleChange("isHideBackground");
                    break;
                  case "isHidePageButton":
                    this.handleChange("isHidePageButton");
                    break;
                  case "isHideMenuButton":
                    this.handleChange("isHideMenuButton");
                    break;
                  case "isApplyFont":
                    this.handleChange("isApplyFont");
                    break;
                  case "isShowFontSize":
                    this.handleChange("isShowFontSize");
                    break;
                  default:
                    break;
                }
              }}
              style={this.state[item.propName] ? {} : { opacity: 0.6 }}
            >
              <span
                className="single-control-button"
                style={
                  !this.state[item.propName]
                    ? {
                      transform: "translateX(0px)",
                      transition: "transform 0.5s ease",
                    }
                    : {
                      transform: "translateX(20px)",
                      transition: "transform 0.5s ease",
                    }
                }
              ></span>
            </span>
          </div>
        ))}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentEpub: state.book.currentEpub,
    currentBook: state.book.currentBook,
    locations: state.progressPanel.locations,
    isReading: state.book.isReading,
    renderFunc: state.book.renderFunc,
  };
};
const actionCreator = {};
export default connect(
  mapStateToProps,
  actionCreator
)(SettingSwitch);
