/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminMemberDto,
    AdminMemberDtoFromJSON,
    AdminMemberDtoToJSON,
} from '../models';

export interface AddAdminMemberUsingPOSTRequest {
    adminMemberRole: number;
    firstname: string;
    lastname: string;
    password: string;
    username: string;
    name?: string;
}

export interface AddAdminMemberUsingPOST1Request {
    adminMemberRole: number;
    firstname: string;
    lastname: string;
    password: string;
    username: string;
    name?: string;
}

export interface DeleteAdminMemberUsingDELETERequest {
    adminMemberId: number;
    name?: string;
}

export interface GetAdminMemberByPrincipalUsingGETRequest {
    name?: string;
}

export interface GetAdminMemberByPrincipalUsingGET1Request {
    name?: string;
}

export interface GetAdminMemberUsingGETRequest {
    adminMemberId: number;
    name?: string;
}

export interface GetAllAdminMemberUsingGETRequest {
    name?: string;
}

export interface UpdateAdminMemberUsingPUTRequest {
    adminMemberId: number;
    name?: string;
    adminMemberRole?: number;
    firstname?: string;
    lastname?: string;
    password?: string;
    username?: string;
}

/**
 * 
 */
export class AdminMemberControllerApi extends runtime.BaseAPI {

    /**
     * addAdminMember
     */
    async addAdminMemberUsingPOSTRaw(requestParameters: AddAdminMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AdminMemberDto>> {
        if (requestParameters.adminMemberRole === null || requestParameters.adminMemberRole === undefined) {
            throw new runtime.RequiredError('adminMemberRole','Required parameter requestParameters.adminMemberRole was null or undefined when calling addAdminMemberUsingPOST.');
        }

        if (requestParameters.firstname === null || requestParameters.firstname === undefined) {
            throw new runtime.RequiredError('firstname','Required parameter requestParameters.firstname was null or undefined when calling addAdminMemberUsingPOST.');
        }

        if (requestParameters.lastname === null || requestParameters.lastname === undefined) {
            throw new runtime.RequiredError('lastname','Required parameter requestParameters.lastname was null or undefined when calling addAdminMemberUsingPOST.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling addAdminMemberUsingPOST.');
        }

        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling addAdminMemberUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.adminMemberRole !== undefined) {
            queryParameters['adminMemberRole'] = requestParameters.adminMemberRole;
        }

        if (requestParameters.firstname !== undefined) {
            queryParameters['firstname'] = requestParameters.firstname;
        }

        if (requestParameters.lastname !== undefined) {
            queryParameters['lastname'] = requestParameters.lastname;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/adminMember`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberDtoFromJSON(jsonValue));
    }

    /**
     * addAdminMember
     */
    async addAdminMemberUsingPOST(requestParameters: AddAdminMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AdminMemberDto> {
        const response = await this.addAdminMemberUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * addAdminMember
     */
    async addAdminMemberUsingPOST1Raw(requestParameters: AddAdminMemberUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AdminMemberDto>> {
        if (requestParameters.adminMemberRole === null || requestParameters.adminMemberRole === undefined) {
            throw new runtime.RequiredError('adminMemberRole','Required parameter requestParameters.adminMemberRole was null or undefined when calling addAdminMemberUsingPOST1.');
        }

        if (requestParameters.firstname === null || requestParameters.firstname === undefined) {
            throw new runtime.RequiredError('firstname','Required parameter requestParameters.firstname was null or undefined when calling addAdminMemberUsingPOST1.');
        }

        if (requestParameters.lastname === null || requestParameters.lastname === undefined) {
            throw new runtime.RequiredError('lastname','Required parameter requestParameters.lastname was null or undefined when calling addAdminMemberUsingPOST1.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling addAdminMemberUsingPOST1.');
        }

        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling addAdminMemberUsingPOST1.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.adminMemberRole !== undefined) {
            queryParameters['adminMemberRole'] = requestParameters.adminMemberRole;
        }

        if (requestParameters.firstname !== undefined) {
            queryParameters['firstname'] = requestParameters.firstname;
        }

        if (requestParameters.lastname !== undefined) {
            queryParameters['lastname'] = requestParameters.lastname;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/adminMember/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberDtoFromJSON(jsonValue));
    }

    /**
     * addAdminMember
     */
    async addAdminMemberUsingPOST1(requestParameters: AddAdminMemberUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AdminMemberDto> {
        const response = await this.addAdminMemberUsingPOST1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteAdminMember
     */
    async deleteAdminMemberUsingDELETERaw(requestParameters: DeleteAdminMemberUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.adminMemberId === null || requestParameters.adminMemberId === undefined) {
            throw new runtime.RequiredError('adminMemberId','Required parameter requestParameters.adminMemberId was null or undefined when calling deleteAdminMemberUsingDELETE.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/adminMember/{adminMemberId}`.replace(`{${"adminMemberId"}}`, encodeURIComponent(String(requestParameters.adminMemberId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteAdminMember
     */
    async deleteAdminMemberUsingDELETE(requestParameters: DeleteAdminMemberUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteAdminMemberUsingDELETERaw(requestParameters, initOverrides);
    }

    /**
     * getAdminMemberByPrincipal
     */
    async getAdminMemberByPrincipalUsingGETRaw(requestParameters: GetAdminMemberByPrincipalUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AdminMemberDto>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/adminMember`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberDtoFromJSON(jsonValue));
    }

    /**
     * getAdminMemberByPrincipal
     */
    async getAdminMemberByPrincipalUsingGET(requestParameters: GetAdminMemberByPrincipalUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AdminMemberDto> {
        const response = await this.getAdminMemberByPrincipalUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAdminMemberByPrincipal
     */
    async getAdminMemberByPrincipalUsingGET1Raw(requestParameters: GetAdminMemberByPrincipalUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AdminMemberDto>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/adminMember/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberDtoFromJSON(jsonValue));
    }

    /**
     * getAdminMemberByPrincipal
     */
    async getAdminMemberByPrincipalUsingGET1(requestParameters: GetAdminMemberByPrincipalUsingGET1Request = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AdminMemberDto> {
        const response = await this.getAdminMemberByPrincipalUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAdminMember
     */
    async getAdminMemberUsingGETRaw(requestParameters: GetAdminMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AdminMemberDto>> {
        if (requestParameters.adminMemberId === null || requestParameters.adminMemberId === undefined) {
            throw new runtime.RequiredError('adminMemberId','Required parameter requestParameters.adminMemberId was null or undefined when calling getAdminMemberUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/adminMember/{adminMemberId}`.replace(`{${"adminMemberId"}}`, encodeURIComponent(String(requestParameters.adminMemberId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberDtoFromJSON(jsonValue));
    }

    /**
     * getAdminMember
     */
    async getAdminMemberUsingGET(requestParameters: GetAdminMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AdminMemberDto> {
        const response = await this.getAdminMemberUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllAdminMember
     */
    async getAllAdminMemberUsingGETRaw(requestParameters: GetAllAdminMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<AdminMemberDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/adminMember/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminMemberDtoFromJSON));
    }

    /**
     * getAllAdminMember
     */
    async getAllAdminMemberUsingGET(requestParameters: GetAllAdminMemberUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<AdminMemberDto>> {
        const response = await this.getAllAdminMemberUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateAdminMember
     */
    async updateAdminMemberUsingPUTRaw(requestParameters: UpdateAdminMemberUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AdminMemberDto>> {
        if (requestParameters.adminMemberId === null || requestParameters.adminMemberId === undefined) {
            throw new runtime.RequiredError('adminMemberId','Required parameter requestParameters.adminMemberId was null or undefined when calling updateAdminMemberUsingPUT.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.adminMemberRole !== undefined) {
            queryParameters['adminMemberRole'] = requestParameters.adminMemberRole;
        }

        if (requestParameters.firstname !== undefined) {
            queryParameters['firstname'] = requestParameters.firstname;
        }

        if (requestParameters.lastname !== undefined) {
            queryParameters['lastname'] = requestParameters.lastname;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/adminMember/{adminMemberId}`.replace(`{${"adminMemberId"}}`, encodeURIComponent(String(requestParameters.adminMemberId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberDtoFromJSON(jsonValue));
    }

    /**
     * updateAdminMember
     */
    async updateAdminMemberUsingPUT(requestParameters: UpdateAdminMemberUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AdminMemberDto> {
        const response = await this.updateAdminMemberUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
