/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    bannerImage?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    categoryName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryDto
     */
    isCollection?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    isHeader?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryDto
     */
    isRecommend?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    isStep?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    mobileBannerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryDto
     */
    root?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    seq?: number;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof CategoryDto
     */
    subCategories?: Array<CategoryDto>;
}

export function CategoryDtoFromJSON(json: any): CategoryDto {
    return CategoryDtoFromJSONTyped(json, false);
}

export function CategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bannerImage': !exists(json, 'bannerImage') ? undefined : json['bannerImage'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'categoryName': !exists(json, 'categoryName') ? undefined : json['categoryName'],
        'isCollection': !exists(json, 'isCollection') ? undefined : json['isCollection'],
        'isHeader': !exists(json, 'isHeader') ? undefined : json['isHeader'],
        'isRecommend': !exists(json, 'isRecommend') ? undefined : json['isRecommend'],
        'isStep': !exists(json, 'isStep') ? undefined : json['isStep'],
        'mobileBannerImage': !exists(json, 'mobileBannerImage') ? undefined : json['mobileBannerImage'],
        'root': !exists(json, 'root') ? undefined : json['root'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
        'subCategories': !exists(json, 'subCategories') ? undefined : ((json['subCategories'] as Array<any>).map(CategoryDtoFromJSON)),
    };
}

export function CategoryDtoToJSON(value?: CategoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bannerImage': value.bannerImage,
        'categoryId': value.categoryId,
        'categoryName': value.categoryName,
        'isCollection': value.isCollection,
        'isHeader': value.isHeader,
        'isRecommend': value.isRecommend,
        'isStep': value.isStep,
        'mobileBannerImage': value.mobileBannerImage,
        'root': value.root,
        'seq': value.seq,
        'subCategories': value.subCategories === undefined ? undefined : ((value.subCategories as Array<any>).map(CategoryDtoToJSON)),
    };
}

