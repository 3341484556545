/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AwardDto,
    AwardDtoFromJSON,
    AwardDtoToJSON,
} from '../models';

export interface AddAwardsUsingPOSTRequest {
    data?: string;
}

/**
 * 
 */
export class AwardControllerApi extends runtime.BaseAPI {

    /**
     * addAwards
     */
    async addAwardsUsingPOSTRaw(requestParameters: AddAwardsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<AwardDto>>> {
        const queryParameters: any = {};

        if (requestParameters.data !== undefined) {
            queryParameters['data'] = requestParameters.data;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/award/adds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AwardDtoFromJSON));
    }

    /**
     * addAwards
     */
    async addAwardsUsingPOST(requestParameters: AddAwardsUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<AwardDto>> {
        const response = await this.addAwardsUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllAward
     */
    async getAllAwardUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<AwardDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/award/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AwardDtoFromJSON));
    }

    /**
     * getAllAward
     */
    async getAllAwardUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<AwardDto>> {
        const response = await this.getAllAwardUsingGETRaw(initOverrides);
        return await response.value();
    }

}
