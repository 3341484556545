// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h1 {\n  margin: 1em 0 1em 0;\n}\n\n/* span {\n  font-size: 12px;\n  color: gray;\n} */\n\n.FrequentlyQuestions_container__s4gmm > span {\n  display: block;\n  margin: 0 0 5px 0;\n}\n\n.FrequentlyQuestions_container__s4gmm > p {\n  margin: 0 0 10px 0;\n}\n\n.FrequentlyQuestions_container__s4gmm > h3 {\n  margin: 30px 0 10px 0;\n}\n\n.FrequentlyQuestions_container__s4gmm {\n  margin: 1.5em;\n}\n\n.FrequentlyQuestions_horizontalLine__xhT6Y {\n  display: block;\n  margin: 2em 0 1em 0;\n  width: 100%;\n  border-top: 1px solid rgb(198 198 198);\n}\n\n.FrequentlyQuestions_textDecoration__kg0VT {\n  text-decoration: underline;\n  margin: 0 0 0.5em 0;\n}\n\n.FrequentlyQuestions_link__6DKo9 {\n  display: block;\n  margin: 0 0 1em 0;\n}\n", "",{"version":3,"sources":["webpack://./src/layout/FrequentlyQuestions/FrequentlyQuestions.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;;;GAGG;;AAEH;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,WAAW;EACX,sCAAsC;AACxC;;AAEA;EACE,0BAA0B;EAC1B,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":["h1 {\n  margin: 1em 0 1em 0;\n}\n\n/* span {\n  font-size: 12px;\n  color: gray;\n} */\n\n.container > span {\n  display: block;\n  margin: 0 0 5px 0;\n}\n\n.container > p {\n  margin: 0 0 10px 0;\n}\n\n.container > h3 {\n  margin: 30px 0 10px 0;\n}\n\n.container {\n  margin: 1.5em;\n}\n\n.horizontalLine {\n  display: block;\n  margin: 2em 0 1em 0;\n  width: 100%;\n  border-top: 1px solid rgb(198 198 198);\n}\n\n.textDecoration {\n  text-decoration: underline;\n  margin: 0 0 0.5em 0;\n}\n\n.link {\n  display: block;\n  margin: 0 0 1em 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FrequentlyQuestions_container__s4gmm",
	"horizontalLine": "FrequentlyQuestions_horizontalLine__xhT6Y",
	"textDecoration": "FrequentlyQuestions_textDecoration__kg0VT",
	"link": "FrequentlyQuestions_link__6DKo9"
};
export default ___CSS_LOADER_EXPORT___;
