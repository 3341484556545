/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SfLocker,
    SfLockerFromJSON,
    SfLockerToJSON,
} from '../models';

export interface FindLockerByAreaUsingGETRequest {
    area?: string;
}

export interface FindLockerByLockerIdUsingPOSTRequest {
    lockerId: string;
}

/**
 * 
 */
export class SfLockerControllerApi extends runtime.BaseAPI {

    /**
     * findAllArea
     */
    async findAllAreaUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/sf-locker/area`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * findAllArea
     */
    async findAllAreaUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<string>> {
        const response = await this.findAllAreaUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * findLockerByArea
     */
    async findLockerByAreaUsingGETRaw(requestParameters: FindLockerByAreaUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<SfLocker>>> {
        const queryParameters: any = {};

        if (requestParameters.area !== undefined) {
            queryParameters['area'] = requestParameters.area;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/sf-locker/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SfLockerFromJSON));
    }

    /**
     * findLockerByArea
     */
    async findLockerByAreaUsingGET(requestParameters: FindLockerByAreaUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<SfLocker>> {
        const response = await this.findLockerByAreaUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * findLockerByLockerId
     */
    async findLockerByLockerIdUsingPOSTRaw(requestParameters: FindLockerByLockerIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SfLocker>> {
        if (requestParameters.lockerId === null || requestParameters.lockerId === undefined) {
            throw new runtime.RequiredError('lockerId','Required parameter requestParameters.lockerId was null or undefined when calling findLockerByLockerIdUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.lockerId !== undefined) {
            queryParameters['lockerId'] = requestParameters.lockerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/sf-locker/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SfLockerFromJSON(jsonValue));
    }

    /**
     * findLockerByLockerId
     */
    async findLockerByLockerIdUsingPOST(requestParameters: FindLockerByLockerIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SfLocker> {
        const response = await this.findLockerByLockerIdUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
