/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MarketingEventDto,
    MarketingEventDtoFromJSON,
    MarketingEventDtoToJSON,
    MarketingEventRequestModel,
    MarketingEventRequestModelFromJSON,
    MarketingEventRequestModelToJSON,
} from '../models';

export interface AddMarketingEventUsingPOSTRequest {
    marketingEventRequestModel?: MarketingEventRequestModel;
}

export interface DeleteProductUsingPOST1Request {
    marketingEventId: number;
    name?: string;
}

export interface GetAllMarketEventWithCouponCodeAdminUsingGETRequest {
    name?: string;
}

export interface GetAllMarketingEventAdminUsingGETRequest {
    name?: string;
}

export interface GetAllMarketingEventBannerAdminUsingGETRequest {
    name?: string;
}

export interface GetMarketingEventByMarketingEventIdAdminUsingGETRequest {
    marketingEventId: number;
    name?: string;
}

export interface GetMarketingEventByMarketingEventIdUsingGETRequest {
    marketingEventId: number;
}

export interface UpdateMarketingEventUsingPOSTRequest {
    marketingEventId: number;
    name?: string;
    marketingEventPriority?: number;
    marketingEventRequestModel?: MarketingEventRequestModel;
}

/**
 * 
 */
export class MarketingEventControllerApi extends runtime.BaseAPI {

    /**
     * addMarketingEvent
     */
    async addMarketingEventUsingPOSTRaw(requestParameters: AddMarketingEventUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MarketingEventDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/marketingEvent/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MarketingEventRequestModelToJSON(requestParameters.marketingEventRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventDtoFromJSON(jsonValue));
    }

    /**
     * addMarketingEvent
     */
    async addMarketingEventUsingPOST(requestParameters: AddMarketingEventUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MarketingEventDto> {
        const response = await this.addMarketingEventUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteProduct
     */
    async deleteProductUsingPOST1Raw(requestParameters: DeleteProductUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MarketingEventDto>> {
        if (requestParameters.marketingEventId === null || requestParameters.marketingEventId === undefined) {
            throw new runtime.RequiredError('marketingEventId','Required parameter requestParameters.marketingEventId was null or undefined when calling deleteProductUsingPOST1.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/marketingEvent/delete/{marketingEventId}`.replace(`{${"marketingEventId"}}`, encodeURIComponent(String(requestParameters.marketingEventId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventDtoFromJSON(jsonValue));
    }

    /**
     * deleteProduct
     */
    async deleteProductUsingPOST1(requestParameters: DeleteProductUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MarketingEventDto> {
        const response = await this.deleteProductUsingPOST1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllMarketEventWithCouponCodeAdmin
     */
    async getAllMarketEventWithCouponCodeAdminUsingGETRaw(requestParameters: GetAllMarketEventWithCouponCodeAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MarketingEventDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/marketingEvent/admin/allWithCouponCode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventDtoFromJSON));
    }

    /**
     * getAllMarketEventWithCouponCodeAdmin
     */
    async getAllMarketEventWithCouponCodeAdminUsingGET(requestParameters: GetAllMarketEventWithCouponCodeAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MarketingEventDto>> {
        const response = await this.getAllMarketEventWithCouponCodeAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllMarketEventWithCouponCode
     */
    async getAllMarketEventWithCouponCodeUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MarketingEventDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/marketingEvent/allWithCouponCode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventDtoFromJSON));
    }

    /**
     * getAllMarketEventWithCouponCode
     */
    async getAllMarketEventWithCouponCodeUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MarketingEventDto>> {
        const response = await this.getAllMarketEventWithCouponCodeUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllMarketingEventAdmin
     */
    async getAllMarketingEventAdminUsingGETRaw(requestParameters: GetAllMarketingEventAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MarketingEventDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/marketingEvent/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventDtoFromJSON));
    }

    /**
     * getAllMarketingEventAdmin
     */
    async getAllMarketingEventAdminUsingGET(requestParameters: GetAllMarketingEventAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MarketingEventDto>> {
        const response = await this.getAllMarketingEventAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllMarketingEventBannerAdmin
     */
    async getAllMarketingEventBannerAdminUsingGETRaw(requestParameters: GetAllMarketingEventBannerAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<{ [key: string]: object; }>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/marketingEvent/admin/banner/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAllMarketingEventBannerAdmin
     */
    async getAllMarketingEventBannerAdminUsingGET(requestParameters: GetAllMarketingEventBannerAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<{ [key: string]: object; }>> {
        const response = await this.getAllMarketingEventBannerAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllMarketingEventBanner
     */
    async getAllMarketingEventBannerUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<{ [key: string]: object; }>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/marketingEvent/banner/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAllMarketingEventBanner
     */
    async getAllMarketingEventBannerUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<{ [key: string]: object; }>> {
        const response = await this.getAllMarketingEventBannerUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllMarketingEventBannerWithPriority
     */
    async getAllMarketingEventBannerWithPriorityUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<{ [key: string]: object; }>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/marketingEvent/banner/allWithPriority`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAllMarketingEventBannerWithPriority
     */
    async getAllMarketingEventBannerWithPriorityUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<{ [key: string]: object; }>> {
        const response = await this.getAllMarketingEventBannerWithPriorityUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllMarketingEvent
     */
    async getAllMarketingEventUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MarketingEventDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/marketingEvent/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventDtoFromJSON));
    }

    /**
     * getAllMarketingEvent
     */
    async getAllMarketingEventUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MarketingEventDto>> {
        const response = await this.getAllMarketingEventUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getMarketingEventByMarketingEventIdAdmin
     */
    async getMarketingEventByMarketingEventIdAdminUsingGETRaw(requestParameters: GetMarketingEventByMarketingEventIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MarketingEventDto>> {
        if (requestParameters.marketingEventId === null || requestParameters.marketingEventId === undefined) {
            throw new runtime.RequiredError('marketingEventId','Required parameter requestParameters.marketingEventId was null or undefined when calling getMarketingEventByMarketingEventIdAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/marketingEvent/admin/{marketingEventId}`.replace(`{${"marketingEventId"}}`, encodeURIComponent(String(requestParameters.marketingEventId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventDtoFromJSON(jsonValue));
    }

    /**
     * getMarketingEventByMarketingEventIdAdmin
     */
    async getMarketingEventByMarketingEventIdAdminUsingGET(requestParameters: GetMarketingEventByMarketingEventIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MarketingEventDto> {
        const response = await this.getMarketingEventByMarketingEventIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMarketingEventByMarketingEventId
     */
    async getMarketingEventByMarketingEventIdUsingGETRaw(requestParameters: GetMarketingEventByMarketingEventIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MarketingEventDto>> {
        if (requestParameters.marketingEventId === null || requestParameters.marketingEventId === undefined) {
            throw new runtime.RequiredError('marketingEventId','Required parameter requestParameters.marketingEventId was null or undefined when calling getMarketingEventByMarketingEventIdUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/marketingEvent/{marketingEventId}`.replace(`{${"marketingEventId"}}`, encodeURIComponent(String(requestParameters.marketingEventId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventDtoFromJSON(jsonValue));
    }

    /**
     * getMarketingEventByMarketingEventId
     */
    async getMarketingEventByMarketingEventIdUsingGET(requestParameters: GetMarketingEventByMarketingEventIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MarketingEventDto> {
        const response = await this.getMarketingEventByMarketingEventIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateMarketingEvent
     */
    async updateMarketingEventUsingPOSTRaw(requestParameters: UpdateMarketingEventUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MarketingEventDto>> {
        if (requestParameters.marketingEventId === null || requestParameters.marketingEventId === undefined) {
            throw new runtime.RequiredError('marketingEventId','Required parameter requestParameters.marketingEventId was null or undefined when calling updateMarketingEventUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.marketingEventId !== undefined) {
            queryParameters['marketingEventId'] = requestParameters.marketingEventId;
        }

        if (requestParameters.marketingEventPriority !== undefined) {
            queryParameters['marketingEventPriority'] = requestParameters.marketingEventPriority;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/marketingEvent/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MarketingEventRequestModelToJSON(requestParameters.marketingEventRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventDtoFromJSON(jsonValue));
    }

    /**
     * updateMarketingEvent
     */
    async updateMarketingEventUsingPOST(requestParameters: UpdateMarketingEventUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MarketingEventDto> {
        const response = await this.updateMarketingEventUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
