/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Article,
    ArticleFromJSON,
    ArticleFromJSONTyped,
    ArticleToJSON,
} from './Article';
import {
    DisplayProductCategory,
    DisplayProductCategoryFromJSON,
    DisplayProductCategoryFromJSONTyped,
    DisplayProductCategoryToJSON,
} from './DisplayProductCategory';
import {
    ProductCategory,
    ProductCategoryFromJSON,
    ProductCategoryFromJSONTyped,
    ProductCategoryToJSON,
} from './ProductCategory';

/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {Array<Article>}
     * @memberof Category
     */
    articlesByCategoryId?: Array<Article>;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    bannerImage?: string;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    categoryName?: string;
    /**
     * 
     * @type {Array<DisplayProductCategory>}
     * @memberof Category
     */
    displayProductCategoriesByCategoryId?: Array<DisplayProductCategory>;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    isCollection?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    isHeader?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    isRecommend?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    isStep?: number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    mobileBannerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    mongoId?: string;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    parentCategoryId?: number;
    /**
     * 
     * @type {Array<ProductCategory>}
     * @memberof Category
     */
    productCategoriesByCategoryId?: Array<ProductCategory>;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    seq?: number;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Category
     */
    subCategoriesByParentCategoryId?: Array<Category>;
}

export function CategoryFromJSON(json: any): Category {
    return CategoryFromJSONTyped(json, false);
}

export function CategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Category {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articlesByCategoryId': !exists(json, 'articlesByCategoryId') ? undefined : ((json['articlesByCategoryId'] as Array<any>).map(ArticleFromJSON)),
        'bannerImage': !exists(json, 'bannerImage') ? undefined : json['bannerImage'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'categoryName': !exists(json, 'categoryName') ? undefined : json['categoryName'],
        'displayProductCategoriesByCategoryId': !exists(json, 'displayProductCategoriesByCategoryId') ? undefined : ((json['displayProductCategoriesByCategoryId'] as Array<any>).map(DisplayProductCategoryFromJSON)),
        'isCollection': !exists(json, 'isCollection') ? undefined : json['isCollection'],
        'isHeader': !exists(json, 'isHeader') ? undefined : json['isHeader'],
        'isRecommend': !exists(json, 'isRecommend') ? undefined : json['isRecommend'],
        'isStep': !exists(json, 'isStep') ? undefined : json['isStep'],
        'mobileBannerImage': !exists(json, 'mobileBannerImage') ? undefined : json['mobileBannerImage'],
        'mongoId': !exists(json, 'mongoId') ? undefined : json['mongoId'],
        'parentCategoryId': !exists(json, 'parentCategoryId') ? undefined : json['parentCategoryId'],
        'productCategoriesByCategoryId': !exists(json, 'productCategoriesByCategoryId') ? undefined : ((json['productCategoriesByCategoryId'] as Array<any>).map(ProductCategoryFromJSON)),
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
        'subCategoriesByParentCategoryId': !exists(json, 'subCategoriesByParentCategoryId') ? undefined : ((json['subCategoriesByParentCategoryId'] as Array<any>).map(CategoryFromJSON)),
    };
}

export function CategoryToJSON(value?: Category | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articlesByCategoryId': value.articlesByCategoryId === undefined ? undefined : ((value.articlesByCategoryId as Array<any>).map(ArticleToJSON)),
        'bannerImage': value.bannerImage,
        'categoryId': value.categoryId,
        'categoryName': value.categoryName,
        'displayProductCategoriesByCategoryId': value.displayProductCategoriesByCategoryId === undefined ? undefined : ((value.displayProductCategoriesByCategoryId as Array<any>).map(DisplayProductCategoryToJSON)),
        'isCollection': value.isCollection,
        'isHeader': value.isHeader,
        'isRecommend': value.isRecommend,
        'isStep': value.isStep,
        'mobileBannerImage': value.mobileBannerImage,
        'mongoId': value.mongoId,
        'parentCategoryId': value.parentCategoryId,
        'productCategoriesByCategoryId': value.productCategoriesByCategoryId === undefined ? undefined : ((value.productCategoriesByCategoryId as Array<any>).map(ProductCategoryToJSON)),
        'seq': value.seq,
        'subCategoriesByParentCategoryId': value.subCategoriesByParentCategoryId === undefined ? undefined : ((value.subCategoriesByParentCategoryId as Array<any>).map(CategoryToJSON)),
    };
}

