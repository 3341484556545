import React from "react";
import Button from "../../components/Button/Button";
import style from "./LowerSideNav.module.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../utils/useWindowSize";
import { useState, useEffect } from "react";

interface Category {
  categoryId: number;
}

export default function LowerSideNav({ props }: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [hidden, setHidden] = useState(true)

  if (width > 1024) {
    return (
      <nav>
        {/* <ul className={style.listContainer}> */}
        <ul className={style.lowerSideNavContainer}>
          <li className={style.lowerSideNavTitle}>
            {t("category.bookClassifications")}
          </li>
          {props &&
            props
              .filter((x: any) => 
                x.categoryId < 10000 &&
                x.isHeader !== 1 &&
                /*
                x.categoryId !== 28 && 
                x.categoryId !== 29 && 
                */
                x.categoryId !== 32
              )
              .sort((a: Category, b: Category) => {
                if (a.categoryId === 35) return -1;
                if (b.categoryId === 35) return 1;
                return 0;
              })
              .map((item: any) => (
                <li
                  key={item.categoryId}
                  className={style.lowerSideNavList}
                  onClick={() => navigate(`/category/${item.categoryId}/`)}
                >
                  {/* <Button
                  buttonType={"button"}
                  styleType={"lowerSideNavButton"}
                  callBack={() => navigate(`category/${item.categoryId}/`)}
                >
                  {item.mobileBannerImage ? (
                    <img
                      style={{ width: "30px" }}
                      src={`${process.env.REACT_APP_BACKEND}file/${item.mobileBannerImage}`}
                    ></img>
                  ) : (
                    <div className={style.circle}></div>
                  )}

                  <p className={style.listName}>{item.categoryName}</p>
                  <ArrowBackIosNewIcon className={style.arrow} />
                </Button> */}
                  {item.mobileBannerImage ? (
                    <img
                      style={{ width: "30px" }}
                      src={`${process.env.REACT_APP_BACKEND}file/${item.mobileBannerImage}`}
                    ></img>
                  ) : (
                    <div className={style.circle}></div>
                  )}

                  <p className={style.listName}>
                    {t(`category.${item.categoryId}`)} ({item.productCount})
                  </p>
                  <ArrowBackIosNewIcon className={style.arrow} />
                </li>
              ))}
        </ul>
      </nav>
    );
  }
  else {
    return (<></>)
  }
}
