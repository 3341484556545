/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Resource,
    ResourceFromJSON,
    ResourceToJSON,
} from '../models';

export interface GetMarcFileUsingGETRequest {
    name?: string;
}

/**
 * 
 */
export class MarcControllerApi extends runtime.BaseAPI {

    /**
     * getMarcFile
     */
    async getMarcFileUsingGETRaw(requestParameters: GetMarcFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/marcreport/downloadMarc`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getMarcFile
     */
    async getMarcFileUsingGET(requestParameters: GetMarcFileUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getMarcFileUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * makemarcResponse
     */
    async makemarcResponseUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/marcreport/getmarc`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * makemarcResponse
     */
    async makemarcResponseUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.makemarcResponseUsingGETRaw(initOverrides);
        return await response.value();
    }

}
