/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReserveBorrow
 */
export interface ReserveBorrow {
    /**
     * 
     * @type {Date}
     * @memberof ReserveBorrow
     */
    estimateBorrowDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReserveBorrow
     */
    isMobile?: string;
    /**
     * 
     * @type {number}
     * @memberof ReserveBorrow
     */
    memberId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReserveBorrow
     */
    needSendEmail?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReserveBorrow
     */
    productId?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReserveBorrow
     */
    reserveDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ReserveBorrow
     */
    reserveId?: number;
    /**
     * 
     * @type {number}
     * @memberof ReserveBorrow
     */
    status?: number;
}

export function ReserveBorrowFromJSON(json: any): ReserveBorrow {
    return ReserveBorrowFromJSONTyped(json, false);
}

export function ReserveBorrowFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReserveBorrow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estimateBorrowDate': !exists(json, 'estimateBorrowDate') ? undefined : (new Date(json['estimateBorrowDate'])),
        'isMobile': !exists(json, 'isMobile') ? undefined : json['isMobile'],
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'needSendEmail': !exists(json, 'needSendEmail') ? undefined : json['needSendEmail'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'reserveDate': !exists(json, 'reserveDate') ? undefined : (new Date(json['reserveDate'])),
        'reserveId': !exists(json, 'reserveId') ? undefined : json['reserveId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function ReserveBorrowToJSON(value?: ReserveBorrow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estimateBorrowDate': value.estimateBorrowDate === undefined ? undefined : (value.estimateBorrowDate.toISOString()),
        'isMobile': value.isMobile,
        'memberId': value.memberId,
        'needSendEmail': value.needSendEmail,
        'productId': value.productId,
        'reserveDate': value.reserveDate === undefined ? undefined : (value.reserveDate.toISOString()),
        'reserveId': value.reserveId,
        'status': value.status,
    };
}

