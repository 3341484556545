/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReserveBorrowDto
 */
export interface ReserveBorrowDto {
    /**
     * 
     * @type {string}
     * @memberof ReserveBorrowDto
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof ReserveBorrowDto
     */
    bookCover?: string;
    /**
     * 
     * @type {string}
     * @memberof ReserveBorrowDto
     */
    bookISBN?: string;
    /**
     * 
     * @type {string}
     * @memberof ReserveBorrowDto
     */
    bookThumbnail?: string;
    /**
     * 
     * @type {string}
     * @memberof ReserveBorrowDto
     */
    bookname?: string;
    /**
     * 
     * @type {number}
     * @memberof ReserveBorrowDto
     */
    borrowTime?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReserveBorrowDto
     */
    estimateBorrowDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ReserveBorrowDto
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReserveBorrowDto
     */
    publisherName?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReserveBorrowDto
     */
    reserveDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ReserveBorrowDto
     */
    resumeBorrowTime?: number;
    /**
     * 
     * @type {string}
     * @memberof ReserveBorrowDto
     */
    seriesName?: string;
    /**
     * 
     * @type {number}
     * @memberof ReserveBorrowDto
     */
    status?: number;
}

export function ReserveBorrowDtoFromJSON(json: any): ReserveBorrowDto {
    return ReserveBorrowDtoFromJSONTyped(json, false);
}

export function ReserveBorrowDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReserveBorrowDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'author': !exists(json, 'author') ? undefined : json['author'],
        'bookCover': !exists(json, 'bookCover') ? undefined : json['bookCover'],
        'bookISBN': !exists(json, 'bookISBN') ? undefined : json['bookISBN'],
        'bookThumbnail': !exists(json, 'bookThumbnail') ? undefined : json['bookThumbnail'],
        'bookname': !exists(json, 'bookname') ? undefined : json['bookname'],
        'borrowTime': !exists(json, 'borrowTime') ? undefined : json['borrowTime'],
        'estimateBorrowDate': !exists(json, 'estimateBorrowDate') ? undefined : (new Date(json['estimateBorrowDate'])),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'publisherName': !exists(json, 'publisherName') ? undefined : json['publisherName'],
        'reserveDate': !exists(json, 'reserveDate') ? undefined : (new Date(json['reserveDate'])),
        'resumeBorrowTime': !exists(json, 'resumeBorrowTime') ? undefined : json['resumeBorrowTime'],
        'seriesName': !exists(json, 'seriesName') ? undefined : json['seriesName'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function ReserveBorrowDtoToJSON(value?: ReserveBorrowDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'author': value.author,
        'bookCover': value.bookCover,
        'bookISBN': value.bookISBN,
        'bookThumbnail': value.bookThumbnail,
        'bookname': value.bookname,
        'borrowTime': value.borrowTime,
        'estimateBorrowDate': value.estimateBorrowDate === undefined ? undefined : (value.estimateBorrowDate.toISOString()),
        'productId': value.productId,
        'publisherName': value.publisherName,
        'reserveDate': value.reserveDate === undefined ? undefined : (value.reserveDate.toISOString()),
        'resumeBorrowTime': value.resumeBorrowTime,
        'seriesName': value.seriesName,
        'status': value.status,
    };
}

