/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProductListDto,
    ProductListDtoFromJSON,
    ProductListDtoToJSON,
} from '../models';

export interface DeleteProductListByIdUsingDELETERequest {
    productListId: number;
}

export interface GetProductListByIdUsingGETRequest {
    productId: number;
}

export interface SaveProductListUsingPOSTRequest {
    name: string;
    description?: string;
}

export interface UpdateProductListByIdUsingPOSTRequest {
    productListId: number;
    name?: string;
    description?: string;
}

/**
 * 
 */
export class ProductListControllerApi extends runtime.BaseAPI {

    /**
     * Delete Product List By Id
     */
    async deleteProductListByIdUsingDELETERaw(requestParameters: DeleteProductListByIdUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductListDto>> {
        if (requestParameters.productListId === null || requestParameters.productListId === undefined) {
            throw new runtime.RequiredError('productListId','Required parameter requestParameters.productListId was null or undefined when calling deleteProductListByIdUsingDELETE.');
        }

        const queryParameters: any = {};

        if (requestParameters.productListId !== undefined) {
            queryParameters['productListId'] = requestParameters.productListId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/productList/deleteById`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductListDtoFromJSON(jsonValue));
    }

    /**
     * Delete Product List By Id
     */
    async deleteProductListByIdUsingDELETE(requestParameters: DeleteProductListByIdUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductListDto> {
        const response = await this.deleteProductListByIdUsingDELETERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All Product List
     */
    async getAllProductListUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ProductListDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/productList/getAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductListDtoFromJSON));
    }

    /**
     * Get All Product List
     */
    async getAllProductListUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ProductListDto>> {
        const response = await this.getAllProductListUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Product List By Id
     */
    async getProductListByIdUsingGETRaw(requestParameters: GetProductListByIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductListDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getProductListByIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/productList/getById`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductListDtoFromJSON(jsonValue));
    }

    /**
     * Get Product List By Id
     */
    async getProductListByIdUsingGET(requestParameters: GetProductListByIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductListDto> {
        const response = await this.getProductListByIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save Product List
     */
    async saveProductListUsingPOSTRaw(requestParameters: SaveProductListUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductListDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling saveProductListUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/productList/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductListDtoFromJSON(jsonValue));
    }

    /**
     * Save Product List
     */
    async saveProductListUsingPOST(requestParameters: SaveProductListUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductListDto> {
        const response = await this.saveProductListUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Product List By Id
     */
    async updateProductListByIdUsingPOSTRaw(requestParameters: UpdateProductListByIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductListDto>> {
        if (requestParameters.productListId === null || requestParameters.productListId === undefined) {
            throw new runtime.RequiredError('productListId','Required parameter requestParameters.productListId was null or undefined when calling updateProductListByIdUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.productListId !== undefined) {
            queryParameters['productListId'] = requestParameters.productListId;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/productList/updateById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductListDtoFromJSON(jsonValue));
    }

    /**
     * Update Product List By Id
     */
    async updateProductListByIdUsingPOST(requestParameters: UpdateProductListByIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductListDto> {
        const response = await this.updateProductListByIdUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
