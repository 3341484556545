/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PagingModelMemberActionLogDto,
    PagingModelMemberActionLogDtoFromJSON,
    PagingModelMemberActionLogDtoToJSON,
} from '../models';

export interface GetLogByMemberUsingGETRequest {
    memberId: number;
    page?: number;
    size?: number;
}

export interface TestingAddLogUsingPOST1Request {
    memberId: number;
    actionCode: string;
    productId: number;
    borrowId: number;
    reserveBorrowId: number;
    page?: number;
    size?: number;
}

/**
 * 
 */
export class MemberActionLogControllerApi extends runtime.BaseAPI {

    /**
     * getLogByMember
     */
    async getLogByMemberUsingGETRaw(requestParameters: GetLogByMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PagingModelMemberActionLogDto>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling getLogByMemberUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member-log/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagingModelMemberActionLogDtoFromJSON(jsonValue));
    }

    /**
     * getLogByMember
     */
    async getLogByMemberUsingGET(requestParameters: GetLogByMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PagingModelMemberActionLogDto> {
        const response = await this.getLogByMemberUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * testingAddLog
     */
    async testingAddLogUsingPOST1Raw(requestParameters: TestingAddLogUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PagingModelMemberActionLogDto>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling testingAddLogUsingPOST1.');
        }

        if (requestParameters.actionCode === null || requestParameters.actionCode === undefined) {
            throw new runtime.RequiredError('actionCode','Required parameter requestParameters.actionCode was null or undefined when calling testingAddLogUsingPOST1.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling testingAddLogUsingPOST1.');
        }

        if (requestParameters.borrowId === null || requestParameters.borrowId === undefined) {
            throw new runtime.RequiredError('borrowId','Required parameter requestParameters.borrowId was null or undefined when calling testingAddLogUsingPOST1.');
        }

        if (requestParameters.reserveBorrowId === null || requestParameters.reserveBorrowId === undefined) {
            throw new runtime.RequiredError('reserveBorrowId','Required parameter requestParameters.reserveBorrowId was null or undefined when calling testingAddLogUsingPOST1.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.actionCode !== undefined) {
            queryParameters['actionCode'] = requestParameters.actionCode;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.borrowId !== undefined) {
            queryParameters['borrowId'] = requestParameters.borrowId;
        }

        if (requestParameters.reserveBorrowId !== undefined) {
            queryParameters['reserveBorrowId'] = requestParameters.reserveBorrowId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member-log/test/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagingModelMemberActionLogDtoFromJSON(jsonValue));
    }

    /**
     * testingAddLog
     */
    async testingAddLogUsingPOST1(requestParameters: TestingAddLogUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PagingModelMemberActionLogDto> {
        const response = await this.testingAddLogUsingPOST1Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
