/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LanguageDto,
    LanguageDtoFromJSON,
    LanguageDtoToJSON,
} from '../models';

export interface AddLanguagesUsingPOSTRequest {
    name?: string;
    data?: string;
}

/**
 * 
 */
export class LanguageControllerApi extends runtime.BaseAPI {

    /**
     * addLanguages
     */
    async addLanguagesUsingPOSTRaw(requestParameters: AddLanguagesUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<LanguageDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.data !== undefined) {
            queryParameters['data'] = requestParameters.data;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/language/adds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LanguageDtoFromJSON));
    }

    /**
     * addLanguages
     */
    async addLanguagesUsingPOST(requestParameters: AddLanguagesUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<LanguageDto>> {
        const response = await this.addLanguagesUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllLanguage
     */
    async getAllLanguageUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<LanguageDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/language/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LanguageDtoFromJSON));
    }

    /**
     * getAllLanguage
     */
    async getAllLanguageUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<LanguageDto>> {
        const response = await this.getAllLanguageUsingGETRaw(initOverrides);
        return await response.value();
    }

}
