/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubCategoryRequestModel,
    SubCategoryRequestModelFromJSON,
    SubCategoryRequestModelFromJSONTyped,
    SubCategoryRequestModelToJSON,
} from './SubCategoryRequestModel';

/**
 * 
 * @export
 * @interface CategoryRequestModel
 */
export interface CategoryRequestModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryRequestModel
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryRequestModel
     */
    mongoId?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryRequestModel
     */
    seq?: number;
    /**
     * 
     * @type {Array<SubCategoryRequestModel>}
     * @memberof CategoryRequestModel
     */
    subCategoryRequestModels?: Array<SubCategoryRequestModel>;
}

export function CategoryRequestModelFromJSON(json: any): CategoryRequestModel {
    return CategoryRequestModelFromJSONTyped(json, false);
}

export function CategoryRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryName': !exists(json, 'categoryName') ? undefined : json['categoryName'],
        'mongoId': !exists(json, 'mongoId') ? undefined : json['mongoId'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
        'subCategoryRequestModels': !exists(json, 'subCategoryRequestModels') ? undefined : ((json['subCategoryRequestModels'] as Array<any>).map(SubCategoryRequestModelFromJSON)),
    };
}

export function CategoryRequestModelToJSON(value?: CategoryRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryName': value.categoryName,
        'mongoId': value.mongoId,
        'seq': value.seq,
        'subCategoryRequestModels': value.subCategoryRequestModels === undefined ? undefined : ((value.subCategoryRequestModels as Array<any>).map(SubCategoryRequestModelToJSON)),
    };
}

