import React from "react";
import { connect } from "react-redux";
import { handleFetchLocations } from "../../../redux/actions/reader";
import { getArgsQuery } from "../../../utils/GlobalFunction";
import StorageUtil from "../../../utils/reader/serviceUtils/storageUtil";
import { withRouter } from "../../../utils/withRouter";
import "./background.css";

class Background extends React.Component {
  // isFirst: Boolean;
  constructor(props) {
    super(props);
    this.state = {
      isSingle:
        StorageUtil.getReaderConfig("readerMode") &&
        StorageUtil.getReaderConfig("readerMode") !== "double",
      currentChapter: "",
      prevPage: 0,
      nextPage: 0,
      scale: StorageUtil.getReaderConfig("scale") || 1,
      isHideFooter: StorageUtil.getReaderConfig("isHideFooter") === "yes",
      isHideHeader: StorageUtil.getReaderConfig("isHideHeader") === "yes",
      isDuobleIFrame: document.getElementsByTagName("iframe").length,
      title: getArgsQuery(this.props.history.location.search).title,
    };
    this.isFirst = true;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentEpub.rendition &&
      nextProps.currentEpub.rendition.location &&
      this.props.currentEpub.rendition
    ) {
      const currentLocation =
        this.props.currentEpub.rendition.currentLocation();
      if (!currentLocation.start) {
        return;
      }
      this.isFirst && this.props.handleFetchLocations(this.props.currentEpub);
      this.isFirst = false;
      /*
      this.setState({
        prevPage: currentLocation.start.index,
        nextPage: currentLocation.end.index,
      });
      */
      if (this.state.isSingle) {
        if (this.state.isDuobleIFrame) {
          this.setState({
            prevPage:
              currentLocation.start.index *
                currentLocation.start.displayed.total +
              currentLocation.start.displayed.page,
            nextPage:
              currentLocation.end.index * currentLocation.end.displayed.total +
              currentLocation.end.displayed.page +
              1,
          });
        } else {
          this.setState({
            prevPage:
              currentLocation.start.index *
                currentLocation.start.displayed.total +
              currentLocation.start.displayed.page,
            nextPage:
              currentLocation.end.index * currentLocation.end.displayed.total +
              currentLocation.end.displayed.page,
          });
        }
      } else {
        let _prevPage =
          currentLocation.start.index * currentLocation.start.displayed.total +
          currentLocation.start.displayed.page;
        let _nextPage =
          currentLocation.end.index * currentLocation.end.displayed.total +
          currentLocation.end.displayed.page;
        try {
          let _target = document.getElementsByClassName("epub-container")[0];
          let _flag = _target.dir === "ltr";
          if (_flag) {
            if (_nextPage === 1) {
              if (_prevPage === 1) {
                _prevPage = 0;
              }
            }
          } else {
            if (_prevPage === 1) {
              if (_nextPage === 1) {
                _nextPage = 0;
              }
            }
          }
        } catch (err) {
          console.log("err", err);
        }
        //console.log("_prevPage", _prevPage, "_nextPage", _nextPage);
        this.setState({
          prevPage: _prevPage,
          nextPage: _nextPage,
        });
      }

      let chapterHref = currentLocation.start.href;

      let chapter = "";
      let currentChapter = this.props.flattenChapters.filter(
        (item) =>
          item.href.indexOf(chapterHref) > -1 ||
          chapterHref.indexOf(item.href) > -1
      )[0];
      if (currentChapter) {
        chapter = currentChapter.label.trim(" ");
      }
      this.setState({ currentChapter: chapter });

      //console.log("Background currentLocation", currentLocation, this.props);
    }
  }

  render() {
    return (
      <div
        className="background"
        style={{
          color: StorageUtil.getReaderConfig("textColor")
            ? StorageUtil.getReaderConfig("textColor")
            : "",
        }}
      >
        <div className="header-container">
          {!this.state.isHideHeader && this.state.currentChapter && (
            <p
              className="header-chapter-name"
              style={
                this.state.isSingle
                  ? {
                      left: `calc(50vw - 
                      270px)`,
                    }
                  : {}
              }
            >
              {this.state.title}
              {/* {this.state.currentChapter} */}
            </p>
          )}
          {!this.state.isHideHeader && !this.state.isSingle && (
            <p
              className="header-book-name"
              style={
                this.state.isSingle
                  ? {
                      right: `calc(50vw - 
                      270px)`,
                    }
                  : {}
              }
            >
              {this.state.title}
              {/* {this.props.currentBook.name} */}
            </p>
          )}
        </div>
        <div className="footer-container">
          {!this.state.isHideFooter && this.state.prevPage >= 0 && (
            <p
              className="background-page-left"
              style={
                this.state.isSingle
                  ? {
                      left: `calc(50vw - 270px)`,
                    }
                  : {}
              }
            >
              {this.state.prevPage === 0 ? "" : this.state.prevPage}
            </p>
          )}
          {!this.state.isHideFooter &&
            this.state.nextPage > 0 &&
            !this.state.isSingle && (
              <p className="background-page-right">{this.state.nextPage}</p>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentEpub: state.book.currentEpub,
    currentBook: state.book.currentBook,
    flattenChapters: state.reader.flattenChapters,
    locations: state.progressPanel.locations,
  };
};
const actionCreator = { handleFetchLocations };
export default connect(mapStateToProps, actionCreator)(withRouter(Background));
