/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Principal,
    PrincipalFromJSON,
    PrincipalToJSON,
} from '../models';

export interface ShowUserDetailUsingGETRequest {
    name?: string;
}

/**
 * 
 */
export class UserControllerApi extends runtime.BaseAPI {

    /**
     * showUserDetail
     */
    async showUserDetailUsingGETRaw(requestParameters: ShowUserDetailUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Principal>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/user/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrincipalFromJSON(jsonValue));
    }

    /**
     * showUserDetail
     */
    async showUserDetailUsingGET(requestParameters: ShowUserDetailUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Principal> {
        const response = await this.showUserDetailUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
