// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ForgetPassword_base__ShRM- {\n    width: 100%;\n    height: 100%;\n}\n\n.ForgetPassword_buttonGroup__0BLF- {\n    position: absolute;\n    display: flex;\n    top: 20px;\n    right: 20px;\n    cursor: pointer;\n}\n\n.ForgetPassword_inputRow__6yCGO {\n    display: flex;\n    width: 100%;\n    height: 50px;\n    margin-bottom: 5px;\n}\n\n.ForgetPassword_inputRow__6yCGO  > * {\n    flex: 1 1;\n  }\n\n.ForgetPassword_inputDiv__-7GxF {\n    flex: 2 1;\n    width: 100%;\n    height: 50px;\n    margin-bottom: 10px;\n    margin-right: 5px;\n}\n\n.ForgetPassword_passwordDiv__B8Zwm {\n    display: flex;\n}", "",{"version":3,"sources":["webpack://./src/layout/Login/ForgetPassword.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,SAAW;EACb;;AAEF;IACI,SAAW;IACX,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".base {\n    width: 100%;\n    height: 100%;\n}\n\n.buttonGroup {\n    position: absolute;\n    display: flex;\n    top: 20px;\n    right: 20px;\n    cursor: pointer;\n}\n\n.inputRow {\n    display: flex;\n    width: 100%;\n    height: 50px;\n    margin-bottom: 5px;\n}\n\n.inputRow  > * {\n    flex: 1 1 0;\n  }\n\n.inputDiv {\n    flex: 2 1 0;\n    width: 100%;\n    height: 50px;\n    margin-bottom: 10px;\n    margin-right: 5px;\n}\n\n.passwordDiv {\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "ForgetPassword_base__ShRM-",
	"buttonGroup": "ForgetPassword_buttonGroup__0BLF-",
	"inputRow": "ForgetPassword_inputRow__6yCGO",
	"inputDiv": "ForgetPassword_inputDiv__-7GxF",
	"passwordDiv": "ForgetPassword_passwordDiv__B8Zwm"
};
export default ___CSS_LOADER_EXPORT___;
