/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminMemberRoleDto,
    AdminMemberRoleDtoFromJSON,
    AdminMemberRoleDtoToJSON,
} from '../models';

export interface AddAdminMemberRoleUsingPOSTRequest {
    adminMemberRoleName: string;
    productManagement: number;
    inventoryManagement: number;
    articleManagement: number;
    productPriceManagement: number;
    discountManagement: number;
    marketingEventManagement: number;
    membershipManagement: number;
    membershipManagementRead: number;
    pointManagement: number;
    admin: number;
    orderManagement: number;
    orderManagementRead: number;
    stockLevelManagement: number;
    name?: string;
}

export interface AddAdminMemberRoleUsingPOST1Request {
    adminMemberRoleName: string;
    productManagement: number;
    inventoryManagement: number;
    articleManagement: number;
    productPriceManagement: number;
    discountManagement: number;
    marketingEventManagement: number;
    membershipManagement: number;
    membershipManagementRead: number;
    pointManagement: number;
    admin: number;
    orderManagement: number;
    orderManagementRead: number;
    stockLevelManagement: number;
    name?: string;
}

export interface DeleteAdminMemberRoleUsingDELETERequest {
    adminMemberRoleId: number;
    name?: string;
}

export interface GetAdminMemberRoleUsingGETRequest {
    adminMemberRoleId: number;
    name?: string;
}

export interface GetAllAdminMemberRoleUsingGETRequest {
    name?: string;
}

export interface UpdateAdminMemberRoleUsingPUTRequest {
    adminMemberRoleId: number;
    name?: string;
    adminMemberRoleName?: string;
    productManagement?: number;
    inventoryManagement?: number;
    articleManagement?: number;
    productPriceManagement?: number;
    discountManagement?: number;
    marketingEventManagement?: number;
    membershipManagement?: number;
    membershipManagementRead?: number;
    pointManagement?: number;
    admin?: number;
    orderManagement?: number;
    orderManagementRead?: number;
    stockLevelManagement?: number;
}

/**
 * 
 */
export class AdminMemberRoleControllerApi extends runtime.BaseAPI {

    /**
     * addAdminMemberRole
     */
    async addAdminMemberRoleUsingPOSTRaw(requestParameters: AddAdminMemberRoleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AdminMemberRoleDto>> {
        if (requestParameters.adminMemberRoleName === null || requestParameters.adminMemberRoleName === undefined) {
            throw new runtime.RequiredError('adminMemberRoleName','Required parameter requestParameters.adminMemberRoleName was null or undefined when calling addAdminMemberRoleUsingPOST.');
        }

        if (requestParameters.productManagement === null || requestParameters.productManagement === undefined) {
            throw new runtime.RequiredError('productManagement','Required parameter requestParameters.productManagement was null or undefined when calling addAdminMemberRoleUsingPOST.');
        }

        if (requestParameters.inventoryManagement === null || requestParameters.inventoryManagement === undefined) {
            throw new runtime.RequiredError('inventoryManagement','Required parameter requestParameters.inventoryManagement was null or undefined when calling addAdminMemberRoleUsingPOST.');
        }

        if (requestParameters.articleManagement === null || requestParameters.articleManagement === undefined) {
            throw new runtime.RequiredError('articleManagement','Required parameter requestParameters.articleManagement was null or undefined when calling addAdminMemberRoleUsingPOST.');
        }

        if (requestParameters.productPriceManagement === null || requestParameters.productPriceManagement === undefined) {
            throw new runtime.RequiredError('productPriceManagement','Required parameter requestParameters.productPriceManagement was null or undefined when calling addAdminMemberRoleUsingPOST.');
        }

        if (requestParameters.discountManagement === null || requestParameters.discountManagement === undefined) {
            throw new runtime.RequiredError('discountManagement','Required parameter requestParameters.discountManagement was null or undefined when calling addAdminMemberRoleUsingPOST.');
        }

        if (requestParameters.marketingEventManagement === null || requestParameters.marketingEventManagement === undefined) {
            throw new runtime.RequiredError('marketingEventManagement','Required parameter requestParameters.marketingEventManagement was null or undefined when calling addAdminMemberRoleUsingPOST.');
        }

        if (requestParameters.membershipManagement === null || requestParameters.membershipManagement === undefined) {
            throw new runtime.RequiredError('membershipManagement','Required parameter requestParameters.membershipManagement was null or undefined when calling addAdminMemberRoleUsingPOST.');
        }

        if (requestParameters.membershipManagementRead === null || requestParameters.membershipManagementRead === undefined) {
            throw new runtime.RequiredError('membershipManagementRead','Required parameter requestParameters.membershipManagementRead was null or undefined when calling addAdminMemberRoleUsingPOST.');
        }

        if (requestParameters.pointManagement === null || requestParameters.pointManagement === undefined) {
            throw new runtime.RequiredError('pointManagement','Required parameter requestParameters.pointManagement was null or undefined when calling addAdminMemberRoleUsingPOST.');
        }

        if (requestParameters.admin === null || requestParameters.admin === undefined) {
            throw new runtime.RequiredError('admin','Required parameter requestParameters.admin was null or undefined when calling addAdminMemberRoleUsingPOST.');
        }

        if (requestParameters.orderManagement === null || requestParameters.orderManagement === undefined) {
            throw new runtime.RequiredError('orderManagement','Required parameter requestParameters.orderManagement was null or undefined when calling addAdminMemberRoleUsingPOST.');
        }

        if (requestParameters.orderManagementRead === null || requestParameters.orderManagementRead === undefined) {
            throw new runtime.RequiredError('orderManagementRead','Required parameter requestParameters.orderManagementRead was null or undefined when calling addAdminMemberRoleUsingPOST.');
        }

        if (requestParameters.stockLevelManagement === null || requestParameters.stockLevelManagement === undefined) {
            throw new runtime.RequiredError('stockLevelManagement','Required parameter requestParameters.stockLevelManagement was null or undefined when calling addAdminMemberRoleUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.adminMemberRoleName !== undefined) {
            queryParameters['adminMemberRoleName'] = requestParameters.adminMemberRoleName;
        }

        if (requestParameters.productManagement !== undefined) {
            queryParameters['productManagement'] = requestParameters.productManagement;
        }

        if (requestParameters.inventoryManagement !== undefined) {
            queryParameters['inventoryManagement'] = requestParameters.inventoryManagement;
        }

        if (requestParameters.articleManagement !== undefined) {
            queryParameters['articleManagement'] = requestParameters.articleManagement;
        }

        if (requestParameters.productPriceManagement !== undefined) {
            queryParameters['productPriceManagement'] = requestParameters.productPriceManagement;
        }

        if (requestParameters.discountManagement !== undefined) {
            queryParameters['discountManagement'] = requestParameters.discountManagement;
        }

        if (requestParameters.marketingEventManagement !== undefined) {
            queryParameters['marketingEventManagement'] = requestParameters.marketingEventManagement;
        }

        if (requestParameters.membershipManagement !== undefined) {
            queryParameters['membershipManagement'] = requestParameters.membershipManagement;
        }

        if (requestParameters.membershipManagementRead !== undefined) {
            queryParameters['membershipManagementRead'] = requestParameters.membershipManagementRead;
        }

        if (requestParameters.pointManagement !== undefined) {
            queryParameters['pointManagement'] = requestParameters.pointManagement;
        }

        if (requestParameters.admin !== undefined) {
            queryParameters['admin'] = requestParameters.admin;
        }

        if (requestParameters.orderManagement !== undefined) {
            queryParameters['orderManagement'] = requestParameters.orderManagement;
        }

        if (requestParameters.orderManagementRead !== undefined) {
            queryParameters['orderManagementRead'] = requestParameters.orderManagementRead;
        }

        if (requestParameters.stockLevelManagement !== undefined) {
            queryParameters['stockLevelManagement'] = requestParameters.stockLevelManagement;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/adminMemberRole`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberRoleDtoFromJSON(jsonValue));
    }

    /**
     * addAdminMemberRole
     */
    async addAdminMemberRoleUsingPOST(requestParameters: AddAdminMemberRoleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AdminMemberRoleDto> {
        const response = await this.addAdminMemberRoleUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * addAdminMemberRole
     */
    async addAdminMemberRoleUsingPOST1Raw(requestParameters: AddAdminMemberRoleUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AdminMemberRoleDto>> {
        if (requestParameters.adminMemberRoleName === null || requestParameters.adminMemberRoleName === undefined) {
            throw new runtime.RequiredError('adminMemberRoleName','Required parameter requestParameters.adminMemberRoleName was null or undefined when calling addAdminMemberRoleUsingPOST1.');
        }

        if (requestParameters.productManagement === null || requestParameters.productManagement === undefined) {
            throw new runtime.RequiredError('productManagement','Required parameter requestParameters.productManagement was null or undefined when calling addAdminMemberRoleUsingPOST1.');
        }

        if (requestParameters.inventoryManagement === null || requestParameters.inventoryManagement === undefined) {
            throw new runtime.RequiredError('inventoryManagement','Required parameter requestParameters.inventoryManagement was null or undefined when calling addAdminMemberRoleUsingPOST1.');
        }

        if (requestParameters.articleManagement === null || requestParameters.articleManagement === undefined) {
            throw new runtime.RequiredError('articleManagement','Required parameter requestParameters.articleManagement was null or undefined when calling addAdminMemberRoleUsingPOST1.');
        }

        if (requestParameters.productPriceManagement === null || requestParameters.productPriceManagement === undefined) {
            throw new runtime.RequiredError('productPriceManagement','Required parameter requestParameters.productPriceManagement was null or undefined when calling addAdminMemberRoleUsingPOST1.');
        }

        if (requestParameters.discountManagement === null || requestParameters.discountManagement === undefined) {
            throw new runtime.RequiredError('discountManagement','Required parameter requestParameters.discountManagement was null or undefined when calling addAdminMemberRoleUsingPOST1.');
        }

        if (requestParameters.marketingEventManagement === null || requestParameters.marketingEventManagement === undefined) {
            throw new runtime.RequiredError('marketingEventManagement','Required parameter requestParameters.marketingEventManagement was null or undefined when calling addAdminMemberRoleUsingPOST1.');
        }

        if (requestParameters.membershipManagement === null || requestParameters.membershipManagement === undefined) {
            throw new runtime.RequiredError('membershipManagement','Required parameter requestParameters.membershipManagement was null or undefined when calling addAdminMemberRoleUsingPOST1.');
        }

        if (requestParameters.membershipManagementRead === null || requestParameters.membershipManagementRead === undefined) {
            throw new runtime.RequiredError('membershipManagementRead','Required parameter requestParameters.membershipManagementRead was null or undefined when calling addAdminMemberRoleUsingPOST1.');
        }

        if (requestParameters.pointManagement === null || requestParameters.pointManagement === undefined) {
            throw new runtime.RequiredError('pointManagement','Required parameter requestParameters.pointManagement was null or undefined when calling addAdminMemberRoleUsingPOST1.');
        }

        if (requestParameters.admin === null || requestParameters.admin === undefined) {
            throw new runtime.RequiredError('admin','Required parameter requestParameters.admin was null or undefined when calling addAdminMemberRoleUsingPOST1.');
        }

        if (requestParameters.orderManagement === null || requestParameters.orderManagement === undefined) {
            throw new runtime.RequiredError('orderManagement','Required parameter requestParameters.orderManagement was null or undefined when calling addAdminMemberRoleUsingPOST1.');
        }

        if (requestParameters.orderManagementRead === null || requestParameters.orderManagementRead === undefined) {
            throw new runtime.RequiredError('orderManagementRead','Required parameter requestParameters.orderManagementRead was null or undefined when calling addAdminMemberRoleUsingPOST1.');
        }

        if (requestParameters.stockLevelManagement === null || requestParameters.stockLevelManagement === undefined) {
            throw new runtime.RequiredError('stockLevelManagement','Required parameter requestParameters.stockLevelManagement was null or undefined when calling addAdminMemberRoleUsingPOST1.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.adminMemberRoleName !== undefined) {
            queryParameters['adminMemberRoleName'] = requestParameters.adminMemberRoleName;
        }

        if (requestParameters.productManagement !== undefined) {
            queryParameters['productManagement'] = requestParameters.productManagement;
        }

        if (requestParameters.inventoryManagement !== undefined) {
            queryParameters['inventoryManagement'] = requestParameters.inventoryManagement;
        }

        if (requestParameters.articleManagement !== undefined) {
            queryParameters['articleManagement'] = requestParameters.articleManagement;
        }

        if (requestParameters.productPriceManagement !== undefined) {
            queryParameters['productPriceManagement'] = requestParameters.productPriceManagement;
        }

        if (requestParameters.discountManagement !== undefined) {
            queryParameters['discountManagement'] = requestParameters.discountManagement;
        }

        if (requestParameters.marketingEventManagement !== undefined) {
            queryParameters['marketingEventManagement'] = requestParameters.marketingEventManagement;
        }

        if (requestParameters.membershipManagement !== undefined) {
            queryParameters['membershipManagement'] = requestParameters.membershipManagement;
        }

        if (requestParameters.membershipManagementRead !== undefined) {
            queryParameters['membershipManagementRead'] = requestParameters.membershipManagementRead;
        }

        if (requestParameters.pointManagement !== undefined) {
            queryParameters['pointManagement'] = requestParameters.pointManagement;
        }

        if (requestParameters.admin !== undefined) {
            queryParameters['admin'] = requestParameters.admin;
        }

        if (requestParameters.orderManagement !== undefined) {
            queryParameters['orderManagement'] = requestParameters.orderManagement;
        }

        if (requestParameters.orderManagementRead !== undefined) {
            queryParameters['orderManagementRead'] = requestParameters.orderManagementRead;
        }

        if (requestParameters.stockLevelManagement !== undefined) {
            queryParameters['stockLevelManagement'] = requestParameters.stockLevelManagement;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/adminMemberRole/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberRoleDtoFromJSON(jsonValue));
    }

    /**
     * addAdminMemberRole
     */
    async addAdminMemberRoleUsingPOST1(requestParameters: AddAdminMemberRoleUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AdminMemberRoleDto> {
        const response = await this.addAdminMemberRoleUsingPOST1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteAdminMemberRole
     */
    async deleteAdminMemberRoleUsingDELETERaw(requestParameters: DeleteAdminMemberRoleUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.adminMemberRoleId === null || requestParameters.adminMemberRoleId === undefined) {
            throw new runtime.RequiredError('adminMemberRoleId','Required parameter requestParameters.adminMemberRoleId was null or undefined when calling deleteAdminMemberRoleUsingDELETE.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/adminMemberRole/{adminMemberRoleId}`.replace(`{${"adminMemberRoleId"}}`, encodeURIComponent(String(requestParameters.adminMemberRoleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteAdminMemberRole
     */
    async deleteAdminMemberRoleUsingDELETE(requestParameters: DeleteAdminMemberRoleUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteAdminMemberRoleUsingDELETERaw(requestParameters, initOverrides);
    }

    /**
     * getAdminMemberRole
     */
    async getAdminMemberRoleUsingGETRaw(requestParameters: GetAdminMemberRoleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AdminMemberRoleDto>> {
        if (requestParameters.adminMemberRoleId === null || requestParameters.adminMemberRoleId === undefined) {
            throw new runtime.RequiredError('adminMemberRoleId','Required parameter requestParameters.adminMemberRoleId was null or undefined when calling getAdminMemberRoleUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/adminMemberRole/{adminMemberRoleId}`.replace(`{${"adminMemberRoleId"}}`, encodeURIComponent(String(requestParameters.adminMemberRoleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberRoleDtoFromJSON(jsonValue));
    }

    /**
     * getAdminMemberRole
     */
    async getAdminMemberRoleUsingGET(requestParameters: GetAdminMemberRoleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AdminMemberRoleDto> {
        const response = await this.getAdminMemberRoleUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllAdminMemberRole
     */
    async getAllAdminMemberRoleUsingGETRaw(requestParameters: GetAllAdminMemberRoleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<AdminMemberRoleDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/adminMemberRole/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminMemberRoleDtoFromJSON));
    }

    /**
     * getAllAdminMemberRole
     */
    async getAllAdminMemberRoleUsingGET(requestParameters: GetAllAdminMemberRoleUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<AdminMemberRoleDto>> {
        const response = await this.getAllAdminMemberRoleUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateAdminMemberRole
     */
    async updateAdminMemberRoleUsingPUTRaw(requestParameters: UpdateAdminMemberRoleUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AdminMemberRoleDto>> {
        if (requestParameters.adminMemberRoleId === null || requestParameters.adminMemberRoleId === undefined) {
            throw new runtime.RequiredError('adminMemberRoleId','Required parameter requestParameters.adminMemberRoleId was null or undefined when calling updateAdminMemberRoleUsingPUT.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.adminMemberRoleName !== undefined) {
            queryParameters['adminMemberRoleName'] = requestParameters.adminMemberRoleName;
        }

        if (requestParameters.productManagement !== undefined) {
            queryParameters['productManagement'] = requestParameters.productManagement;
        }

        if (requestParameters.inventoryManagement !== undefined) {
            queryParameters['inventoryManagement'] = requestParameters.inventoryManagement;
        }

        if (requestParameters.articleManagement !== undefined) {
            queryParameters['articleManagement'] = requestParameters.articleManagement;
        }

        if (requestParameters.productPriceManagement !== undefined) {
            queryParameters['productPriceManagement'] = requestParameters.productPriceManagement;
        }

        if (requestParameters.discountManagement !== undefined) {
            queryParameters['discountManagement'] = requestParameters.discountManagement;
        }

        if (requestParameters.marketingEventManagement !== undefined) {
            queryParameters['marketingEventManagement'] = requestParameters.marketingEventManagement;
        }

        if (requestParameters.membershipManagement !== undefined) {
            queryParameters['membershipManagement'] = requestParameters.membershipManagement;
        }

        if (requestParameters.membershipManagementRead !== undefined) {
            queryParameters['membershipManagementRead'] = requestParameters.membershipManagementRead;
        }

        if (requestParameters.pointManagement !== undefined) {
            queryParameters['pointManagement'] = requestParameters.pointManagement;
        }

        if (requestParameters.admin !== undefined) {
            queryParameters['admin'] = requestParameters.admin;
        }

        if (requestParameters.orderManagement !== undefined) {
            queryParameters['orderManagement'] = requestParameters.orderManagement;
        }

        if (requestParameters.orderManagementRead !== undefined) {
            queryParameters['orderManagementRead'] = requestParameters.orderManagementRead;
        }

        if (requestParameters.stockLevelManagement !== undefined) {
            queryParameters['stockLevelManagement'] = requestParameters.stockLevelManagement;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/adminMemberRole/{adminMemberRoleId}`.replace(`{${"adminMemberRoleId"}}`, encodeURIComponent(String(requestParameters.adminMemberRoleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberRoleDtoFromJSON(jsonValue));
    }

    /**
     * updateAdminMemberRole
     */
    async updateAdminMemberRoleUsingPUT(requestParameters: UpdateAdminMemberRoleUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AdminMemberRoleDto> {
        const response = await this.updateAdminMemberRoleUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
