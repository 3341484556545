/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PointRuleDto,
    PointRuleDtoFromJSON,
    PointRuleDtoToJSON,
} from '../models';

export interface AddPointRuleUsingPOSTRequest {
    pointRuleName: string;
    period: string;
    startDate: Date;
    endDate: Date;
    appiedProduct: number;
    appiedMemberLevel: number;
    name?: string;
    cashForOnePoint?: number;
    pointToOneCash?: number;
    oneCashToPoint?: number;
    forBirthday?: string;
}

export interface DeletePointRuleUsingPOSTRequest {
    pointRuleId: number;
    name?: string;
}

export interface GetAllPointRuleUsingGETRequest {
    name?: string;
}

export interface GetPointRuleUsingGETRequest {
    pointRuleId: number;
    name?: string;
}

export interface PointToCashUsingPOSTRequest {
    point: number;
}

export interface UpdatePointRuleUsingPOSTRequest {
    pointRuleId: number;
    name?: string;
    pointRuleName?: string;
    cashForOnePoint?: number;
    pointToOneCash?: number;
    oneCashToPoint?: number;
    period?: string;
    startDate?: Date;
    endDate?: Date;
    appiedProduct?: number;
    appiedMemberLevel?: number;
    forBirthday?: string;
}

/**
 * 
 */
export class PointRuleControllerApi extends runtime.BaseAPI {

    /**
     * addPointRule
     */
    async addPointRuleUsingPOSTRaw(requestParameters: AddPointRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        if (requestParameters.pointRuleName === null || requestParameters.pointRuleName === undefined) {
            throw new runtime.RequiredError('pointRuleName','Required parameter requestParameters.pointRuleName was null or undefined when calling addPointRuleUsingPOST.');
        }

        if (requestParameters.period === null || requestParameters.period === undefined) {
            throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling addPointRuleUsingPOST.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling addPointRuleUsingPOST.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling addPointRuleUsingPOST.');
        }

        if (requestParameters.appiedProduct === null || requestParameters.appiedProduct === undefined) {
            throw new runtime.RequiredError('appiedProduct','Required parameter requestParameters.appiedProduct was null or undefined when calling addPointRuleUsingPOST.');
        }

        if (requestParameters.appiedMemberLevel === null || requestParameters.appiedMemberLevel === undefined) {
            throw new runtime.RequiredError('appiedMemberLevel','Required parameter requestParameters.appiedMemberLevel was null or undefined when calling addPointRuleUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.pointRuleName !== undefined) {
            queryParameters['pointRuleName'] = requestParameters.pointRuleName;
        }

        if (requestParameters.cashForOnePoint !== undefined) {
            queryParameters['cashForOnePoint'] = requestParameters.cashForOnePoint;
        }

        if (requestParameters.pointToOneCash !== undefined) {
            queryParameters['pointToOneCash'] = requestParameters.pointToOneCash;
        }

        if (requestParameters.oneCashToPoint !== undefined) {
            queryParameters['oneCashToPoint'] = requestParameters.oneCashToPoint;
        }

        if (requestParameters.period !== undefined) {
            queryParameters['period'] = requestParameters.period;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.appiedProduct !== undefined) {
            queryParameters['appiedProduct'] = requestParameters.appiedProduct;
        }

        if (requestParameters.appiedMemberLevel !== undefined) {
            queryParameters['appiedMemberLevel'] = requestParameters.appiedMemberLevel;
        }

        if (requestParameters.forBirthday !== undefined) {
            queryParameters['forBirthday'] = requestParameters.forBirthday;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pointRule/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * addPointRule
     */
    async addPointRuleUsingPOST(requestParameters: AddPointRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PointRuleDto> {
        const response = await this.addPointRuleUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deletePointRule
     */
    async deletePointRuleUsingPOSTRaw(requestParameters: DeletePointRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        if (requestParameters.pointRuleId === null || requestParameters.pointRuleId === undefined) {
            throw new runtime.RequiredError('pointRuleId','Required parameter requestParameters.pointRuleId was null or undefined when calling deletePointRuleUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pointRule/delete/{pointRuleId}`.replace(`{${"pointRuleId"}}`, encodeURIComponent(String(requestParameters.pointRuleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * deletePointRule
     */
    async deletePointRuleUsingPOST(requestParameters: DeletePointRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PointRuleDto> {
        const response = await this.deletePointRuleUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllPointRule
     */
    async getAllPointRuleUsingGETRaw(requestParameters: GetAllPointRuleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PointRuleDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pointRule/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PointRuleDtoFromJSON));
    }

    /**
     * getAllPointRule
     */
    async getAllPointRuleUsingGET(requestParameters: GetAllPointRuleUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PointRuleDto>> {
        const response = await this.getAllPointRuleUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToOneCash`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PointRuleDto> {
        const response = await this.getPointRulePointToOneCashUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getPointRule
     */
    async getPointRuleUsingGETRaw(requestParameters: GetPointRuleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        if (requestParameters.pointRuleId === null || requestParameters.pointRuleId === undefined) {
            throw new runtime.RequiredError('pointRuleId','Required parameter requestParameters.pointRuleId was null or undefined when calling getPointRuleUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pointRule/{pointRuleId}`.replace(`{${"pointRuleId"}}`, encodeURIComponent(String(requestParameters.pointRuleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * getPointRule
     */
    async getPointRuleUsingGET(requestParameters: GetPointRuleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PointRuleDto> {
        const response = await this.getPointRuleUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * pointToCash
     */
    async pointToCashUsingPOSTRaw(requestParameters: PointToCashUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.point === null || requestParameters.point === undefined) {
            throw new runtime.RequiredError('point','Required parameter requestParameters.point was null or undefined when calling pointToCashUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToCash/{point}`.replace(`{${"point"}}`, encodeURIComponent(String(requestParameters.point))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * pointToCash
     */
    async pointToCashUsingPOST(requestParameters: PointToCashUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.pointToCashUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updatePointRule
     */
    async updatePointRuleUsingPOSTRaw(requestParameters: UpdatePointRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        if (requestParameters.pointRuleId === null || requestParameters.pointRuleId === undefined) {
            throw new runtime.RequiredError('pointRuleId','Required parameter requestParameters.pointRuleId was null or undefined when calling updatePointRuleUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.pointRuleId !== undefined) {
            queryParameters['pointRuleId'] = requestParameters.pointRuleId;
        }

        if (requestParameters.pointRuleName !== undefined) {
            queryParameters['pointRuleName'] = requestParameters.pointRuleName;
        }

        if (requestParameters.cashForOnePoint !== undefined) {
            queryParameters['cashForOnePoint'] = requestParameters.cashForOnePoint;
        }

        if (requestParameters.pointToOneCash !== undefined) {
            queryParameters['pointToOneCash'] = requestParameters.pointToOneCash;
        }

        if (requestParameters.oneCashToPoint !== undefined) {
            queryParameters['oneCashToPoint'] = requestParameters.oneCashToPoint;
        }

        if (requestParameters.period !== undefined) {
            queryParameters['period'] = requestParameters.period;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.appiedProduct !== undefined) {
            queryParameters['appiedProduct'] = requestParameters.appiedProduct;
        }

        if (requestParameters.appiedMemberLevel !== undefined) {
            queryParameters['appiedMemberLevel'] = requestParameters.appiedMemberLevel;
        }

        if (requestParameters.forBirthday !== undefined) {
            queryParameters['forBirthday'] = requestParameters.forBirthday;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pointRule/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * updatePointRule
     */
    async updatePointRuleUsingPOST(requestParameters: UpdatePointRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PointRuleDto> {
        const response = await this.updatePointRuleUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
