// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../assets/color.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UpperSideNav_upperSideNavContainer__hHd5I {\n  margin: 0 1em;\n}\n\n.UpperSideNav_upperSideNavList__tJMdg {\n  position: relative;\n  /* margin: 50px; */\n  padding: 15px 0;\n  list-style: none;\n  display: flex;\n  cursor: pointer;\n  color: #009da5;\n}\n\n.UpperSideNav_upperSideNavList__tJMdg::after {\n  content: \"\";\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 1px;\n  background-color: rgb(232, 232, 232);\n}\n", "",{"version":3,"sources":["webpack://./src/layout/SideNav/UpperSideNav.module.css"],"names":[],"mappings":"AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,WAAW;EACX,oCAAoC;AACtC","sourcesContent":["@import \"/src/assets/color.css\";\n\n.upperSideNavContainer {\n  margin: 0 1em;\n}\n\n.upperSideNavList {\n  position: relative;\n  /* margin: 50px; */\n  padding: 15px 0;\n  list-style: none;\n  display: flex;\n  cursor: pointer;\n  color: #009da5;\n}\n\n.upperSideNavList::after {\n  content: \"\";\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 1px;\n  background-color: rgb(232, 232, 232);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upperSideNavContainer": "UpperSideNav_upperSideNavContainer__hHd5I",
	"upperSideNavList": "UpperSideNav_upperSideNavList__tJMdg"
};
export default ___CSS_LOADER_EXPORT___;
