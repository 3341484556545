/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SfStation,
    SfStationFromJSON,
    SfStationToJSON,
} from '../models';

export interface FindLockerByAreaUsingGET1Request {
    area?: string;
}

export interface FindStationByStationIdUsingPOSTRequest {
    stationId?: string;
}

/**
 * 
 */
export class SfStationControllerApi extends runtime.BaseAPI {

    /**
     * findAllArea
     */
    async findAllAreaUsingGET1Raw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/sf-station/area`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * findAllArea
     */
    async findAllAreaUsingGET1(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<string>> {
        const response = await this.findAllAreaUsingGET1Raw(initOverrides);
        return await response.value();
    }

    /**
     * findLockerByArea
     */
    async findLockerByAreaUsingGET1Raw(requestParameters: FindLockerByAreaUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<SfStation>>> {
        const queryParameters: any = {};

        if (requestParameters.area !== undefined) {
            queryParameters['area'] = requestParameters.area;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/sf-station/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SfStationFromJSON));
    }

    /**
     * findLockerByArea
     */
    async findLockerByAreaUsingGET1(requestParameters: FindLockerByAreaUsingGET1Request = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<SfStation>> {
        const response = await this.findLockerByAreaUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * findStationByStationId
     */
    async findStationByStationIdUsingPOSTRaw(requestParameters: FindStationByStationIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SfStation>> {
        const queryParameters: any = {};

        if (requestParameters.stationId !== undefined) {
            queryParameters['stationId'] = requestParameters.stationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/sf-station/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SfStationFromJSON(jsonValue));
    }

    /**
     * findStationByStationId
     */
    async findStationByStationIdUsingPOST(requestParameters: FindStationByStationIdUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SfStation> {
        const response = await this.findStationByStationIdUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
