/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PageContentDto,
    PageContentDtoFromJSON,
    PageContentDtoToJSON,
} from '../models';

export interface AddPageContentUsingPOSTRequest {
    content: string;
    pageName: string;
    name?: string;
}

export interface DeleteArticleUsingPOST1Request {
    pageContentId: number;
    name?: string;
}

export interface GetPageContentByIdUsingGETRequest {
    pageContentId: number;
}

export interface GetPageContentByPageNameUsingGETRequest {
    pageName: string;
}

export interface UpdatePageContentUsingPOSTRequest {
    pageContentId: number;
    content: string;
    pageName: string;
    name?: string;
}

/**
 * 
 */
export class PageContentControllerApi extends runtime.BaseAPI {

    /**
     * addPageContent
     */
    async addPageContentUsingPOSTRaw(requestParameters: AddPageContentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PageContentDto>> {
        if (requestParameters.content === null || requestParameters.content === undefined) {
            throw new runtime.RequiredError('content','Required parameter requestParameters.content was null or undefined when calling addPageContentUsingPOST.');
        }

        if (requestParameters.pageName === null || requestParameters.pageName === undefined) {
            throw new runtime.RequiredError('pageName','Required parameter requestParameters.pageName was null or undefined when calling addPageContentUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.content !== undefined) {
            queryParameters['content'] = requestParameters.content;
        }

        if (requestParameters.pageName !== undefined) {
            queryParameters['pageName'] = requestParameters.pageName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pageContent/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageContentDtoFromJSON(jsonValue));
    }

    /**
     * addPageContent
     */
    async addPageContentUsingPOST(requestParameters: AddPageContentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PageContentDto> {
        const response = await this.addPageContentUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteArticle
     */
    async deleteArticleUsingPOST1Raw(requestParameters: DeleteArticleUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PageContentDto>> {
        if (requestParameters.pageContentId === null || requestParameters.pageContentId === undefined) {
            throw new runtime.RequiredError('pageContentId','Required parameter requestParameters.pageContentId was null or undefined when calling deleteArticleUsingPOST1.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pageContent/delete/{pageContentId}`.replace(`{${"pageContentId"}}`, encodeURIComponent(String(requestParameters.pageContentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageContentDtoFromJSON(jsonValue));
    }

    /**
     * deleteArticle
     */
    async deleteArticleUsingPOST1(requestParameters: DeleteArticleUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PageContentDto> {
        const response = await this.deleteArticleUsingPOST1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllPageContent
     */
    async getAllPageContentUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PageContentDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pageContent/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PageContentDtoFromJSON));
    }

    /**
     * getAllPageContent
     */
    async getAllPageContentUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PageContentDto>> {
        const response = await this.getAllPageContentUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getPageContentById
     */
    async getPageContentByIdUsingGETRaw(requestParameters: GetPageContentByIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PageContentDto>> {
        if (requestParameters.pageContentId === null || requestParameters.pageContentId === undefined) {
            throw new runtime.RequiredError('pageContentId','Required parameter requestParameters.pageContentId was null or undefined when calling getPageContentByIdUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pageContent/{pageContentId}`.replace(`{${"pageContentId"}}`, encodeURIComponent(String(requestParameters.pageContentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageContentDtoFromJSON(jsonValue));
    }

    /**
     * getPageContentById
     */
    async getPageContentByIdUsingGET(requestParameters: GetPageContentByIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PageContentDto> {
        const response = await this.getPageContentByIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPageContentByPageName
     */
    async getPageContentByPageNameUsingGETRaw(requestParameters: GetPageContentByPageNameUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PageContentDto>> {
        if (requestParameters.pageName === null || requestParameters.pageName === undefined) {
            throw new runtime.RequiredError('pageName','Required parameter requestParameters.pageName was null or undefined when calling getPageContentByPageNameUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pageContent/pageName/{pageName}`.replace(`{${"pageName"}}`, encodeURIComponent(String(requestParameters.pageName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageContentDtoFromJSON(jsonValue));
    }

    /**
     * getPageContentByPageName
     */
    async getPageContentByPageNameUsingGET(requestParameters: GetPageContentByPageNameUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PageContentDto> {
        const response = await this.getPageContentByPageNameUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updatePageContent
     */
    async updatePageContentUsingPOSTRaw(requestParameters: UpdatePageContentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PageContentDto>> {
        if (requestParameters.pageContentId === null || requestParameters.pageContentId === undefined) {
            throw new runtime.RequiredError('pageContentId','Required parameter requestParameters.pageContentId was null or undefined when calling updatePageContentUsingPOST.');
        }

        if (requestParameters.content === null || requestParameters.content === undefined) {
            throw new runtime.RequiredError('content','Required parameter requestParameters.content was null or undefined when calling updatePageContentUsingPOST.');
        }

        if (requestParameters.pageName === null || requestParameters.pageName === undefined) {
            throw new runtime.RequiredError('pageName','Required parameter requestParameters.pageName was null or undefined when calling updatePageContentUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.pageContentId !== undefined) {
            queryParameters['pageContentId'] = requestParameters.pageContentId;
        }

        if (requestParameters.content !== undefined) {
            queryParameters['content'] = requestParameters.content;
        }

        if (requestParameters.pageName !== undefined) {
            queryParameters['pageName'] = requestParameters.pageName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pageContent/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageContentDtoFromJSON(jsonValue));
    }

    /**
     * updatePageContent
     */
    async updatePageContentUsingPOST(requestParameters: UpdatePageContentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PageContentDto> {
        const response = await this.updatePageContentUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
